<template>
  <div class="mb-4">
    <flux-card-button-header
      class="m-2"
      :title="
        $t('patient.create.form.step.identity_documents.identity_document')
      "
      :action="$t('patient.create.form.step.identity_documents.add_more')"
      @action="addEmptyIdentityDocument()"
      icon="fal fa-plus"
    />
    <flux-card
      class="test"
      v-for="(
        identity_document, index
      ) in identityDocumentsForm.identity_documents"
      :key="index"
      style="margin-bottom: 10px"
      shadow="never"
    >
      <clickable-icon-group>
        <clickable-icon
          icon="fa-times"
          @click="removeIdentityDocument(index)"
        />
      </clickable-icon-group>
      <flux-form>
        <flux-short-form>
          <flux-short-form-item
            :label="$t('patient.create.form.step.identity_documents.type')"
            span="col-span-12 md:col-span-6"
          >
            <flux-select v-model:modelValue="identity_document.type">
              <option
                v-for="document_type in documentTypes"
                :key="document_type"
                :value="document_type"
                :label="$t(`identity_documents.type.${document_type}`)"
              >
                {{ $t(`identity_documents.type.${document_type}`) }}
              </option>
            </flux-select>
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.step.identity_documents.number')"
            span="col-span-12 md:col-span-6"
          >
            <flux-input
              v-model:modelValue="identity_document.number"
            ></flux-input>
          </flux-short-form-item>
        </flux-short-form>
        <flux-button-group class="mx-4 mt-8 justify-end">
          <flux-submit-button
            v-if="!!identity_document.id"
            @click="updateIdentityDocument(index)"
          >
            {{ $t("general.update") }}
          </flux-submit-button>
          <flux-submit-button v-else @click="saveNewIdentityDocument(index)">
            {{ $t("general.save") }}
          </flux-submit-button>
        </flux-button-group>
      </flux-form>
    </flux-card>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.identity_documents.identity')"
    />
    <flux-card
      class="mb-2"
      v-flux-loading="nationalitiesLoading"
      shadow="never"
    >
      <flux-form :errors="errors">
        <flux-short-form>
          <flux-short-form-item
            :label="$t('patient.create.form.bsn')"
            span="col-span-6"
          >
            <flux-input
              v-model:modelValue="identityDocumentsForm.bsn"
              :disabled="!fetchedBsn"
              prop="bsn"
              inputClass="pr-6"
              :icon="!fetchedBsn ? 'fal fa-lock' : ''"
            >
              <template
                v-if="
                  !numberIsNotBsn &&
                  fetchedBsn &&
                  identityDocumentsForm.bsn &&
                  identityDocumentsForm.bsn.length > 0
                "
                #suffix
              >
                <i
                  class="fas fa-check"
                  v-if="bsnIsValid(identityDocumentsForm.bsn).success"
                  style="color: #14ce68; margin-right: 6px"
                />
                <i
                  class="fas fa-times"
                  v-else
                  style="color: #ef3e36; margin-right: 6px"
                />
              </template>
            </flux-input>
            <div class="text-sm text-gray-600" v-if="fetchedBsn">
              <flux-checkbox-label class="mt-4" prop="bsn">
                <flux-checkbox v-model:modelValue="numberIsNotBsn" />
                Nummer is geen BSN
              </flux-checkbox-label>
            </div>
          </flux-short-form-item>
          <flux-short-form-item v-if="!fetchedBsn" span="col-span-6">
            <flux-button :disabled="loadingBsn" @click="show()" size="small">{{
              $t("patient.edit.identity_documents.bsn.show")
            }}</flux-button>
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.date_of_birth')"
            prop="date_of_birth"
            span="col-span-12 sm:col-span-8"
          >
            <flux-input
              v-model:modelValue="identityDocumentsForm.date_of_birth"
              prop="date_of_birth"
              icon="fal fa-calendar-alt"
              type="date"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="$t('patient.create.form.nationality')"
            span="col-span-12 sm:col-span-8"
          >
            <flux-multiselect
              v-model:modelValue="identityDocumentsForm.nationalities"
              :options="nationalities"
              idKey="id"
              labelKey="description"
            />
          </flux-short-form-item>
        </flux-short-form>
        <flux-button-group class="mx-4 mt-8 justify-end">
          <flux-submit-button @click="save()" type="primary">
            {{ $t("general.update") }}
          </flux-submit-button>
        </flux-button-group>
      </flux-form>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { computed, onMounted, reactive, ref, toRef, watch } from "vue";
import { Nationality } from "@/models/Nationality";
import { bsnIsValid } from "../../libraries/utils/bsn";
import { useConfirm } from "@/composables/confirm";
import { useNotify } from "@/composables/notify";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { isValidationErrorResponse } from "@/libraries/utils/errorHandling";
import { Patient, usePatient, useUpdatePatient } from "@/composables/patient";
import { $t } from "@/libraries/i18n";
import moment from "moment";
import { pinia } from "@/libraries/store";
import { useNationalityStore } from "@/libraries/store/Nationalities";
import { NationalIdentityNumberType } from "@/models/Forms";
import { getBsn } from "@/queries/bsn/bsn";

const { confirm } = useConfirm();
const { notify } = useNotify();

const props = defineProps<{ zisNumber: number }>();

const fetchedBsn = ref(false);
const loadingBsn = ref(false);

const { data: patient } = usePatient(toRef(props, "zisNumber"));

const { updatePatient } = useUpdatePatient(toRef(props, "zisNumber"));

interface IdForm {
  bsn?: string;
  national_identity_number_type?: NationalIdentityNumberType;
  nationalities: number[];
  date_of_birth: string;
  identity_documents: Array<{
    type: string;
    number: string;
    id?: number;
  }>;
}
const identityDocumentsForm: IdForm = reactive({
  bsn: undefined,
  national_identity_number_type: undefined,
  date_of_birth: "",
  nationalities: [],
  identity_documents: [],
});

const errors = ref(new MessageBag({}));

const nationalitiesLoading = ref(false);

const nationalities = ref<Nationality[]>([]);

const documentTypes = [
  "passport",
  "id_card",
  "drivers_license",
  "alien_passport",
  "refugee_passport",
  "official_passport",
  "business_passport",
  "second_passport",
  "diplomatic_passport",
  "emergency_passport",
  "laissez-passer",
  "other",
] as const;

watch(patient, onPatientChanged, { immediate: true, deep: false });
function onPatientChanged(val: Patient | undefined) {
  if (!val) {
    return;
  }

  identityDocumentsForm.nationalities = val.nationalities.map((n) => n.id);
  identityDocumentsForm.national_identity_number_type =
    val.national_identity_number_type;
  if (!!val.date_of_birth) {
    identityDocumentsForm.date_of_birth = moment(val.date_of_birth).format(
      "YYYY-MM-DD",
    );
  }
}

const numberIsNotBsn = computed({
  get: (): boolean => {
    return (
      identityDocumentsForm.national_identity_number_type !== undefined &&
      identityDocumentsForm.national_identity_number_type !== "bsn"
    );
  },
  set: (isNotBsn: boolean) => {
    identityDocumentsForm.national_identity_number_type = isNotBsn
      ? "other"
      : "bsn";
  },
});

onMounted(async () => {
  nationalities.value = await useNationalityStore(pinia).findAll();
  fetchIdentityDocuments();
});

function fetchIdentityDocuments() {
  const zis_number = patient.value!.zis_number;
  apiClient
    .get<"/patients/:zis_number/identity_documents">(
      `/patients/${zis_number}/identity_documents`,
    )
    .then(({ data }) => {
      identityDocumentsForm.identity_documents = data.identity_documents;
    })
    .catch(() => {
      notify({
        message: "Could not fetch identity documents",
        type: "error",
      });
    });
}

async function show() {
  loadingBsn.value = true;
  try {
    const res = await getBsn(patient.value!.zis_number);
    if (res?.success) {
      fetchedBsn.value = true;
      identityDocumentsForm.bsn = res.bsn;
      if (!identityDocumentsForm.bsn) {
        notify({
          message: "Er is geen BSN bekend voor deze patiënt.",
          type: "warning",
        });
      }
      updateNationalIdentityNumberType(identityDocumentsForm.bsn);
    } else {
      notify({
        message: "Could not fetch bsn.",
        type: "error",
      });
    }
  } catch (e) {
    notify({
      message: "Could not fetch bsn due to an error.",
      type: "error",
    });
    throw e;
  } finally {
    loadingBsn.value = false;
  }
}

function updateNationalIdentityNumberType(bsn?: string) {
  if (!bsn || identityDocumentsForm.national_identity_number_type) {
    return;
  }
  numberIsNotBsn.value = !bsnIsValid(bsn).success;
  quietlySaveIdentityNumberType();
}

async function removeIdentityDocument(index: number) {
  if (index < identityDocumentsForm.identity_documents.length) {
    try {
      if (!identityDocumentsForm.identity_documents[index].id) {
        identityDocumentsForm.identity_documents.splice(index, 1);
        return;
      }
      if (
        await confirm({
          message: $t(
            "patient.edit.identity_documents.documents.delete.message",
          ),
          title: $t("patient.edit.identity_documents.documents.delete.title"),
          type: "delete",
        })
      ) {
        const id = identityDocumentsForm.identity_documents[index].id;
        if (!patient.value) {
          throw new Error(
            "tried to delete identity document, but patient prop was undefined.",
          );
        }
        await apiClient.delete("/patients/:zis_number/identity_documents/:id", {
          params: {
            zis_number: patient.value.zis_number,
            id,
          },
        });
      }
      identityDocumentsForm.identity_documents.splice(index, 1);
    } catch {
      notify({
        type: "error",
        message: $t("patient.edit.identity_documents.documents.delete.fail"),
      });
    }
  }
}

function addEmptyIdentityDocument() {
  const emptyIdentityDocument = {
    type: "id_card",
    number: "",
  };

  identityDocumentsForm.identity_documents = [
    ...identityDocumentsForm.identity_documents,
    emptyIdentityDocument,
  ];
}

function saveNewIdentityDocument(index: number) {
  if (!patient.value) {
    throw new Error(
      "Tried to update/save identity_document, but patient prop was undefined",
    );
  }
  if (index >= identityDocumentsForm.identity_documents.length) {
    throw new Error(
      "Tried to save identity_document," +
        "but a non-existent index of the identity_documents array was given.",
    );
  }
  const identityDocument = identityDocumentsForm.identity_documents[index];
  if (!!identityDocument.id) {
    return updateIdentityDocument(index);
  }

  apiClient
    .post("/patients/:zis_number/identity_documents/", identityDocument, {
      params: {
        zis_number: patient.value.zis_number,
      },
    })
    .then((res) => {
      if (res.data.success) {
        notify({
          title: "Success",
          message: $t("patient.edit.identity_documents.documents.success"),
          type: "success",
        });
        identityDocumentsForm.identity_documents[index] =
          res.data.identity_document;
      } else {
        notify({
          title: "Failed",
          type: "error",
          message: $t("patient.edit.identity_documents.documents.errors"),
        });
      }
    })
    .catch((reason) => {
      notify({
        title: "Failed",
        type: "error",
        message: $t("patient.edit.identity_documents.documents.errors"),
      });
    });
}

function updateIdentityDocument(index: number) {
  if (!patient.value) {
    throw new Error(
      "Tried to update/save identity_document, but patient prop was undefined",
    );
  }

  if (index >= identityDocumentsForm.identity_documents.length) {
    throw new Error(
      "Tried to update identity_document," +
        "but a non-existent index of the identity_documents array was given.",
    );
  }

  const identityDocument = identityDocumentsForm.identity_documents[index];
  if (!identityDocument.id) {
    throw new Error(
      "Tried to update identity_document without an existing id.",
    );
  }

  apiClient
    .put("/patients/:zis_number/identity_documents/:id", identityDocument, {
      params: {
        zis_number: patient.value.zis_number,
        id: identityDocument.id,
      },
    })
    .then((res) => {
      if (res.data.success) {
        notify({
          title: "Success",
          message: $t("patient.edit.identity_documents.documents.success"),
          type: "success",
        });
        identityDocumentsForm.identity_documents[index] =
          res.data.identity_document;
      } else {
        notify({
          title: "Failed",
          type: "error",
          message: $t("patient.edit.identity_documents.documents.errors"),
        });
      }
    })
    .catch((reason) => {
      notify({
        title: "Failed",
        type: "error",
        message: $t("patient.edit.identity_documents.documents.errors"),
      });
    });
}

async function save() {
  try {
    nationalitiesLoading.value = true;
    const payload: any = {
      date_of_birth: identityDocumentsForm.date_of_birth,
      nationalities: identityDocumentsForm.nationalities,
    };
    if (identityDocumentsForm.bsn !== undefined) {
      payload.bsn = identityDocumentsForm.bsn;
      if (identityDocumentsForm.bsn !== "") {
        payload.national_identity_number_type =
          identityDocumentsForm.national_identity_number_type ?? "bsn";
      }
    }

    await updatePatient(payload);
    notify({
      type: "success",
      title: "Success",
      message: $t("patient.edit.identity_documents.identity.success"),
    });
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      errors.value = MessageBag.fromResponse(e.response);
    }
    notify({
      type: "error",
      title: "An error occured",
      message: $t("patient.edit.identity_documents.identity.errors"),
    });
  }
  nationalitiesLoading.value = false;
}

function quietlySaveIdentityNumberType() {
  updatePatient({
    national_identity_number_type:
      identityDocumentsForm.national_identity_number_type,
  });
}
</script>
