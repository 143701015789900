<template>
  <div>
    <flux-dropdown direction="left">
      <template #button="slotProps">
        <div
          class="group mt-1 flex cursor-pointer select-none items-center space-x-2 rounded-lg p-2 outline-none transition duration-75 hover:bg-slate-100 focus:bg-slate-100 focus:ring"
          tabindex="0"
          @click="
            ($event) => {
              slotProps.handleClick($event);
              $event.preventDefault();
            }
          "
          @keydown.enter="
            ($event) => {
              slotProps.handleKeyDown($event);
              $event.preventDefault();
            }
          "
        >
          <Avatar v-if="avatar" :avatar="avatar" :big="true"></Avatar>
          <span
            class="hidden text-gray-600 group-hover:text-gray-800 group-focus:text-gray-800 xl:inline"
            >{{ profile_button_name }}</span
          >
        </div>
      </template>
      <flux-dropdown-item icon="fal fa-gift" @click="openReleaseNotes()">{{
        $t("releasenotes.name")
      }}</flux-dropdown-item>
      <flux-dropdown-item
        v-if="hasFeatureFlag('tech-support')"
        icon="far fa-question-circle"
        @click="showIntercomModalHandler"
      >
        Neem contact op
      </flux-dropdown-item>
      <hr class="my-1 border-b border-gray-200" />
      <flux-dropdown-item icon="fab fa-linkedin" @click="openLinkedIn()"
        >Volg Flux op LinkedIn</flux-dropdown-item
      >
      <flux-dropdown-item icon="fab fa-instagram" @click="openInstagram()"
        >Volg @fluxmedicalsystems op Instagram
      </flux-dropdown-item>
      <hr class="my-1 border-b border-gray-200" />
      <flux-dropdown-item icon="fal fa-sign-out" @click="logout">{{
        $t("general.logout")
      }}</flux-dropdown-item>
    </flux-dropdown>
    <flux-modal
      class="w-full max-w-3xl"
      v-model:visible="showIntercomModal"
      :title="$t('support_modal.title')"
    >
      <div class="flex flex-col gap-2">
        <div class="text-sm text-gray-600">
          {{ $t("support_modal.subtitle") }}
        </div>
        <flux-long-form-divider />
        <flux-textarea
          v-model="messageBody"
          ref="focusInput"
          :minRows="6"
          :maxRows="12"
          :placeholder="$t('support_modal.placeholder')"
        />
      </div>
      <template #footer>
        <flux-button
          type="primary"
          @click="submitSupportMessage()"
          :disabled="!messageBody"
        >
          {{ $t("general.send") }}
        </flux-button>
      </template>
    </flux-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { authModule } from "@/auth";
import { fullNameInitials } from "@/models/Person";
import { telemetryManager } from "@/libraries/telemetry/Manager";
import { getUser } from "@/libraries/plugins/getUser";
import { useRouter } from "vue-router";
import store from "@/libraries/store";
import { apiClient } from "@/libraries/utils/axios";
import Avatar from "@/components/Avatar.vue";
import { useNotify } from "@/composables/notify";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

const router = useRouter();
const { notify } = useNotify();

const profile_button_name = computed(() => fullNameInitials(getUser()));
const avatar = computed(() => {
  const userId = getUser().id;
  return getUser().healthcare_provider.users.find((user) => user.id === userId)
    ?.avatar;
});

const showIntercomModal = ref(false);
const messageBody = ref<string>();
const focusInput = ref();

function submitSupportMessage() {
  apiClient.post("/tech_support/create_thread", {
    body: messageBody.value,
  });
  notify({
    title: "Het bericht is verzonden",
    message: "Er zal antwoord worden gegeven via de mail",
    type: "success",
  });
  messageBody.value = "";
  showIntercomModal.value = false;
}

function showIntercomModalHandler() {
  showIntercomModal.value = true;
  setTimeout(() => {
    // Use timeout to make sure the textarea is visible and rendered. Was not able to make it work with onMounted in textarea.
    focusInput.value.focus();
  }, 100);
}

function logout() {
  authModule(store).logout();
  router.push("/login");
}

function openLinkedIn() {
  window.open(
    "https://www.linkedin.com/company/flux-medical-systems/",
    "_blank",
    "noopener",
  );
}

function openInstagram() {
  window.open(
    "https://www.instagram.com/fluxmedicalsystems",
    "_blank",
    "noopener",
  );
}

function openReleaseNotes() {
  telemetryManager.queueEntry({
    action: "releasenotes.open.profilemenu",
  });
  router.push("/releasenotes").catch(() => {});
  return true;
}
</script>
