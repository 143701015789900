import { z } from "zod";

export interface KeyStore {
  getDecryptionKey(id: string): Promise<CryptoKey>;
  getEncryptionKey(id: string): Promise<CryptoKey>;
}

interface Metadata<K extends IDBValidKey> {
  symmetricKey: JsonWebKey;
  storageKey: K;
  iv: Uint8Array;
}

export interface EncryptedStoredData {
  encryptionKeyId: string;
  metadataPayload: ArrayBuffer;
  dataPayload: ArrayBuffer;
}

export default class EncryptedStorage<K extends IDBValidKey, T>
  implements FluxStorage<K, T>
{
  private storage: FluxStorage<ArrayBuffer, EncryptedStoredData>;
  private keyStore: KeyStore;
  private currentEncryptionKeyId: string;
  private currentEncryptionKey?: CryptoKey;
  private textEncoder: TextEncoder;
  private textDecoder: TextDecoder;

  constructor(
    storage: FluxStorage<ArrayBuffer, EncryptedStoredData>,
    encryptionKeyId: string,
    keyStore: KeyStore,
  ) {
    this.storage = storage;
    this.keyStore = keyStore;
    this.currentEncryptionKeyId = encryptionKeyId;
    this.textEncoder = new TextEncoder();
    this.textDecoder = new TextDecoder();
  }

  public async setEncryptionKey(
    encryptionKeyId: string,
    reEncryptAll: boolean,
  ) {
    this.currentEncryptionKeyId = encryptionKeyId;
    this.currentEncryptionKey =
      await this.keyStore.getEncryptionKey(encryptionKeyId);
  }

  private async encryptionKey(): Promise<CryptoKey> {
    if (this.currentEncryptionKey === undefined) {
      this.currentEncryptionKey = await this.keyStore.getEncryptionKey(
        this.currentEncryptionKeyId,
      );
    }

    return this.currentEncryptionKey;
  }

  public async setItem(
    storageKey: K,
    data: T,
    expiration?: number,
  ): Promise<void> {
    const symmetricKey = await crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"],
    );

    const iv = crypto.getRandomValues(new Uint8Array(12));

    const jwk = await crypto.subtle.exportKey("jwk", symmetricKey);

    return this.storage.setItem(
      await crypto.subtle.digest(
        "SHA-256",
        this.textEncoder.encode(JSON.stringify(storageKey)),
      ),
      {
        encryptionKeyId: this.currentEncryptionKeyId,
        metadataPayload: await crypto.subtle.encrypt(
          { name: "RSA-OAEP" },
          await this.encryptionKey(),
          this.textEncoder.encode(
            JSON.stringify({
              symmetricKey: jwk,
              storageKey: storageKey,
              iv: Array.from(iv),
            }),
          ),
        ),
        dataPayload: await crypto.subtle.encrypt(
          {
            name: "AES-GCM",
            iv: iv,
          },
          symmetricKey,
          this.textEncoder.encode(JSON.stringify(data)),
        ),
      },
      expiration,
    );
  }

  public async getItem(storageKey: K): Promise<T> {
    const data = await this.storage.getItem(
      await crypto.subtle.digest(
        "SHA-256",
        this.textEncoder.encode(JSON.stringify(storageKey)),
      ),
    );
    const metadataZod = z.object({
      symmetricKey: z.object({
        alg: z.string(),
        ext: z.boolean(),
        k: z.string(),
        key_ops: z.array(z.string()),
        kty: z.string(),
      }),
      storageKey: z.any(),
      iv: z.array(z.number()).transform((arg: number[]): Uint8Array => {
        return Uint8Array.from(arg);
      }),
    });

    const metadata = metadataZod.parse(
      JSON.parse(
        this.textDecoder.decode(
          await crypto.subtle.decrypt(
            { name: "RSA-OAEP" },
            await this.keyStore.getDecryptionKey(data.encryptionKeyId),
            data.metadataPayload,
          ),
        ),
      ),
    );

    const symmetricKey = await crypto.subtle.importKey(
      "jwk",
      metadata.symmetricKey,
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["decrypt"],
    );

    const data2 = JSON.parse(
      this.textDecoder.decode(
        await crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: metadata.iv,
          },
          symmetricKey,
          data.dataPayload,
        ),
      ),
    );

    return data2;
  }

  public async removeItem(storageKey: K): Promise<void> {
    return this.storage.removeItem(
      await crypto.subtle.digest(
        "SHA-256",
        this.textEncoder.encode(JSON.stringify(storageKey)),
      ),
    );
  }

  public async getAllKeys(): Promise<K[]> {
    const data = await this.storage.getAll();
    const results: K[] = [];
    for (const value of data) {
      const metadata: Metadata<K> = JSON.parse(
        this.textDecoder.decode(
          await crypto.subtle.decrypt(
            { name: "RSA-OAEP" },
            await this.keyStore.getDecryptionKey(value.data.encryptionKeyId),
            value.data.metadataPayload,
          ),
        ),
      );
      results.push(metadata.storageKey);
    }
    return results;
  }

  public async getAll(): Promise<{ key: K; data: T }[]> {
    const data = await this.storage.getAll();
    const results: { key: K; data: T }[] = [];
    for (const value of data) {
      const metadataZod = z.object({
        symmetricKey: z.object({
          alg: z.string(),
          ext: z.boolean(),
          k: z.string(),
          key_ops: z.array(z.string()),
          kty: z.string(),
        }),
        storageKey: z.any(),
        iv: z.array(z.number()).transform((arg: number[]): Uint8Array => {
          return Uint8Array.from(arg);
        }),
      });

      const metadata = metadataZod.parse(
        JSON.parse(
          this.textDecoder.decode(
            await crypto.subtle.decrypt(
              { name: "RSA-OAEP" },
              await this.keyStore.getDecryptionKey(value.data.encryptionKeyId),
              value.data.metadataPayload,
            ),
          ),
        ),
      );

      const symmetricKey = await crypto.subtle.importKey(
        "jwk",
        metadata.symmetricKey,
        {
          name: "AES-GCM",
          length: 256,
        },
        true,
        ["decrypt"],
      );

      results.push({
        key: metadata.storageKey,
        data: JSON.parse(
          this.textDecoder.decode(
            await crypto.subtle.decrypt(
              {
                name: "AES-GCM",
                iv: metadata.iv,
              },
              symmetricKey,
              value.data.dataPayload,
            ),
          ),
        ),
      });
    }
    return results;
  }
}
