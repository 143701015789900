import { z } from "zod";
import { Country, countrySchema } from "./Country";
import { parseISO } from "date-fns";

export const addressTypeOptions = [
  "PST",
  "HP",
  "PHYS",
  "TMP",
  "WP",
  "HV",
] as const;
export type AddressType = (typeof addressTypeOptions)[number];

export const addressSchema = z.object({
  id: z.number(),
  street_name: z.string().optional(),
  house_number: z.string().optional(),
  postal_code: z.string().optional(),
  city: z.string().optional(),
  additional_information: z.string().optional(),
  country_id: z.number(),
  country: countrySchema.optional(),
  address_type: z.enum(addressTypeOptions),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.string().transform((s) => parseISO(s)),
});

export type Address = z.infer<typeof addressSchema>;

export interface AddressForm {
  address_type: AddressType;
  country_id: number;
  postal_code: string;
  house_number: string;
  street_name: string;
  city: string;
}

export function toLetterString(address: Address) {
  return (
    address.street_name +
    " " +
    address.house_number +
    " " +
    address.postal_code +
    " " +
    address.city
  );
}
