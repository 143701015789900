import moment from "moment";
import { PayerInsurerPersisted } from "./PayerInsurer";

export const insuranceTypes = ["B", "A", "BZ", "H", "T", "AT", "OTH"] as const;
export type InsuranceTypes = (typeof insuranceTypes)[number];

export interface Insurer {
  id: number;
  name: string;
  /**
   * UZOVI number
   */
  number: string;
  start_date: string;
  end_date?: string;
  mutated_at: string;
}

export const normalizeUzovi = (uzovi: string): string => uzovi.padStart(4, "0");

export function insurerIsActive(
  insurer: Insurer,
  start_date: string,
  end_date?: string | null,
): Boolean {
  if (moment(insurer.start_date).isAfter(start_date)) {
    return false;
  }
  return !insurer.end_date || moment(insurer.end_date).isSameOrAfter(end_date);
}

export function getInsurerName(
  insurers: Insurer[],
  payerInsurer: PayerInsurerPersisted,
) {
  if (!payerInsurer.insurer_id) {
    return;
  }
  return insurers.find(({ id }) => payerInsurer.insurer_id === id)?.name;
}
