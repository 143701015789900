import Vue from "vue";
import { createI18n } from "vue-i18n";
import nl from "../../assets/locales/nl.json";
import en from "../../assets/locales/en.json";

const i18n = createI18n({
  locale: "nl",
  legacy: false,
  messages: {
    nl,
    en,
  },
});

export function $t(key: string, values?: any): string {
  const response = i18n.global.t(key, values);
  if (typeof response !== "string") {
    throw new Error(`Expected i18n key ${key} to return string`);
  }

  return response;
}

export function $tc(key: string, value?: number): string {
  return "not supported";
  // const response = i18n.global.tc(key, value);
  // if (typeof response !== "string") {
  //   throw new Error(`Expected i18n key ${key} to return string`);
  // }

  // return response;
}

// https://kazupon.github.io/vue-i18n/dynamic.html
export default i18n;
