/**
 * Inject Axios into Vue component
 */

import Vue from "vue";
import { UserFromSync } from "../store/interfaces";
import { AppName } from "@/models/App";
import store from "../store";

export function getUser(): UserFromSync {
  if (!store.state.user) {
    throw new Error("user was not found");
  }

  return store.state.user;
}

export function hasApp(app: AppName): boolean {
  if (!store.state.apps) {
    throw new Error("apps array not found");
  }

  return store.state.apps.includes(app);
}

export const install = (vue: any) => {
  // (vue.prototype as Vue)
  vue.config.globalProperties.getUser = function (): UserFromSync {
    if (!this.store.state.user) {
      throw new Error("user was not found");
    }

    return this.store.state.user;
  };

  // (vue.prototype as Vue)
  vue.config.globalProperties.hasApp = function (app: AppName): boolean {
    if (!this.store.state.apps) {
      throw new Error("apps array not found");
    }

    return this.store.state.apps.includes(app);
  };
};
