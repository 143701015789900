class ModalManager {
  symbols = new Set<Symbol>();

  public registerModal(symbol: Symbol) {
    this.symbols.add(symbol);
    this.handleCss();
  }

  public unregisterModal(symbol: Symbol) {
    this.symbols.delete(symbol);
    this.handleCss();
  }

  private handleCss() {
    if (this.symbols.size === 0) {
      document.body.classList.remove("modal-open");
    } else {
      document.body.classList.add("modal-open");
    }
  }
}

export default new ModalManager();
