import { invoiceTypes } from "@/apis/patients/invoices";
import { appointmentStatus } from "@/models/Appointment";
import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse, handleErrors } from "@/libraries/utils/errorHandling";
import { invoiceFilterStatus } from "@/models/Invoice";
import { InjectionKey } from "vue";
import { z } from "zod";
import { declarationEntryStatus } from "../DeclarationEntry/declarationEntryRepository";
import { declarationEntryAnnotationSchema } from "../DeclarationEntryAnnotations/DeclarationEntryAnnotationsRepository";

const EntrySchema = z.object({
  appointment_id: z.number().optional(),
  date: z.string(),
  appointment_status: z.enum(appointmentStatus).optional(),
  appointment_type_id: z.number().optional(),
  appointment_type_name: z.string().optional(),
  appointment_type_product_ids: z.array(z.string()).optional(),
  appointment_type_package_uuid: z.string().optional(),
  invoice_entry_id: z.number().optional(),
  invoice_entry_price: z.number().optional(),
  invoice_entry_amount: z
    .union([z.string().transform(Number), z.number()])
    .optional(),
  invoice_entry_agb_code: z.string().optional(),
  invoice_entry_reference: z.string().optional(),
  invoice_entry_credit_reference: z.string().optional(),
  invoice_entry_date_treatment: z.string().optional(),
  invoice_id: z.number().optional(),
  collection_invoice_id: z.number().optional(),
  invoice_number: z.string().optional(),
  invoice_filter_status: z.enum(invoiceFilterStatus).optional(),
  invoice_type: z.enum(invoiceTypes).optional(),
  declaration_entry_id: z.string().optional(),
  price_claimed: z.number().optional(),
  declaration_entry_status: z.enum(declarationEntryStatus).optional(),
  external_reference: z.string().optional(),
  external_invoice_reference: z.string().optional(),
  referral_id: z.number().optional(),
  treatment_count: z.number().optional(),
  csi_code: z.string().optional(),
  uzovi: z.string().optional(),
  dcsph_code: z.string().optional(),
  prestatiecode: z.string().optional(),
  user_ids: z.array(z.number()),
  annotations: z.array(declarationEntryAnnotationSchema),
  import_data: z.record(z.string(), z.any()).optional(),
});

const AppointmentSchema = EntrySchema.extend({
  appointment_id: z.number(),
  appointment_status: z.enum(appointmentStatus),
  block_generation: z.string().optional(),
  csi_codes: z.array(z.string().optional()).optional(),
  prestatiecodes: z.array(z.string().optional()).optional(),
  prices: z.array(z.number()).optional(),
  declaration_entry_statusses: z
    .array(z.enum(declarationEntryStatus))
    .optional(),
});

const appointmentWithHistorySchema = AppointmentSchema.extend({
  history: z.array(AppointmentSchema),
});

const InvoiceEntrySchema = EntrySchema.extend({
  invoice_entry_id: z.number(),
  invoice_entry_price: z.number(),
  invoice_id: z.number(),
  invoice_filter_status: z.enum(invoiceFilterStatus),
});

const AppointmentSchemaList = z.object({
  financial_history: z.array(
    z.object({
      id: z.number().optional(),
      is_active: z.boolean(),
      is_trashed: z.boolean(),
      is_chronic: z.boolean().optional(),
      is_declarable: z.boolean().optional(),
      indication_accident: z.string().optional(),
      hcp_diagnosis: z.string().optional(),
      diagnosis_code: z.string().optional(),
      default_csi_code: z.string().optional(),
      chronic_periods: z
        .array(
          z.object({
            start_date: z.string().optional(),
            end_date: z.string().optional(),
            number_of_treatments: z.number().optional(),
            default_csi_code: z.string().optional(),
          }),
        )
        .optional(),
      referral_has_chronic_periods: z.boolean().optional(),
      appointments: z.array(appointmentWithHistorySchema),
      invoice_entries: z.array(InvoiceEntrySchema),
      declaration_entries: z.array(EntrySchema),
      imported_declaration_entry_ids: z.array(z.string()),
    }),
  ),
  imported_declaration_entries: z.array(EntrySchema),
});

export type FinancialHistoryEntry = z.infer<typeof EntrySchema>;

export type InvoiceEntryWithFinancialData = z.infer<typeof InvoiceEntrySchema>;

export type AppointmentWithFinancialData = z.infer<
  typeof appointmentWithHistorySchema
>;

export type AnyEntry =
  | FinancialHistoryEntry
  | InvoiceEntryWithFinancialData
  | AppointmentWithFinancialData;

export type AppointmentWithFinancialDataList = z.infer<
  typeof AppointmentSchemaList
>["financial_history"];

export const PatientFinancialHistoryRepositoryKey = Symbol() as InjectionKey<
  () => PatientFinancialHistoryRepository
>;

export interface PatientFinancialHistoryRepository {
  findAllForPatient(
    zisNumber: number,
  ): Promise<AppointmentWithFinancialDataList>;
}

export class PatientFinancialHistoryRepositoryUsingApi
  implements PatientFinancialHistoryRepository
{
  async findAllForPatient(
    zisNumber: number,
  ): Promise<AppointmentWithFinancialDataList> {
    try {
      const { data } = await apiClient.get(
        `/patients/${zisNumber}/financial_history`,
      );
      const parsed: ReturnType<(typeof AppointmentSchemaList)["parse"]> =
        handledZodParse({
          schema: AppointmentSchemaList,
          input: data,
          notifyUser: false,
          reportError: true,
          throwOnFail: false,
        });
      parsed.financial_history.forEach((referral) =>
        Object.assign(referral, {
          appointments: referral.imported_declaration_entry_ids
            .map((id) =>
              parsed.imported_declaration_entries.find(
                (entry) => entry.declaration_entry_id === id,
              ),
            )
            .concat(referral.appointments)
            .sort((a, b) => {
              if (a!.date < b!.date) {
                return -1;
              }
              if (a!.date > b!.date) {
                return 1;
              }
              return 0;
            }),
        }),
      );
      return parsed.financial_history;
    } catch (error) {
      handleErrors(error);
    }
  }
}
