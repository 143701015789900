import { Hotkey } from "../../models/Hotkey";

class HotkeyManager {
  private register = new Map<string, { symbol: Symbol; action: () => void }>();
  private pressedKeysHashRegister = new Set<string>();

  public registerHotkey(hotkey: Hotkey, symbol: Symbol, action: () => void) {
    if (
      hotkey.secondKey &&
      (hotkey.altKey || hotkey.ctrlMeta || hotkey.shiftKey)
    ) {
      throw new Error("Cannot use modifier keys and second key.");
    }
    if (this.hotkeyIsAlreadyRegistered(hotkey)) {
      console.warn("Duplicate hotkey");
      console.warn(hotkey);
    }
    this.register.set(this.hotkeyToHash(hotkey), { symbol, action });
  }

  public unregisterHotkey(hotkey: Hotkey, symbol: Symbol) {
    const reg = this.register.get(this.hotkeyToHash(hotkey));
    if (reg?.symbol === symbol) {
      this.register.delete(this.hotkeyToHash(hotkey));
    }
  }

  public triggerHotkeyIfRegistered(event: KeyboardEvent) {
    if (!event.key) {
      return;
    }
    if (this.register.has(this.eventToHash(event))) {
      this.register.get(this.eventToHash(event))?.action();
      return;
    }
    for (const hashPart of this.pressedKeysHashRegister.values()) {
      if (this.register.has(hashPart + this.eventToHash(event))) {
        this.register.get(hashPart + this.eventToHash(event))?.action();
        return;
      }
    }
    this.registerPressedKey(event);
  }

  private registerPressedKey(event: KeyboardEvent) {
    this.pressedKeysHashRegister.add(this.eventToHash(event));
    setTimeout(
      this.deletePressedKey,
      500,
      this.pressedKeysHashRegister,
      this.eventToHash(event),
    );
  }

  public deletePressedKey(register: Set<string>, hash: string) {
    register.delete(hash);
  }

  private hotkeyToHash(hotkey: Hotkey) {
    const isMac = navigator.userAgent.includes("Mac");
    if (hotkey.secondKey) {
      return hotkey.key.toLowerCase() + hotkey.secondKey.toLowerCase();
    }
    return `${hotkey.altKey ? "alt" : ""}${
      hotkey.ctrlMeta ? (isMac ? "meta" : "ctrl") : ""
    }${hotkey.shiftKey ? "shift" : ""}${hotkey.key.toLowerCase()}`;
  }

  private eventToHash(event: KeyboardEvent) {
    const keyVal = this.getKeyValue(event);
    if (keyVal === "/") {
      //Disregard modifiers for compatibility with other layouts
      return keyVal;
    }

    return `${event.altKey ? "alt" : ""}${event.ctrlKey ? "ctrl" : ""}${
      event.metaKey ? "meta" : ""
    }${event.shiftKey ? "shift" : ""}${keyVal}`;
  }

  private getKeyValue(event: KeyboardEvent) {
    if (event.code.includes("Digit")) {
      return event.code.slice(-1);
    }
    return event.key.toLowerCase();
  }

  private hotkeyIsAlreadyRegistered(hotkey: Hotkey) {
    return this.register.has(this.hotkeyToHash(hotkey));
  }
}

export default new HotkeyManager();
