<template>
  <div class="contents" v-flux-loading="loading">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.contact_info.title')"
    />
    <flux-form>
      <flux-card shadow="never">
        <flux-input-box label="Emails uitzetten">
          <div class="flex flex-col gap-2">
            <flux-multiselect
              v-model:modelValue="form.suppressed_email_types"
              :options="suppressedEmailTypes"
              idKey="id"
              :labelKey="(e) => $t(`email_sents.type.${e.id}`)"
            />
          </div>
        </flux-input-box>
        <flux-short-form>
          <div
            class="contents"
            v-for="(phone_number, index) in form.phone_numbers"
            :key="'phone' + index.toString()"
          >
            <flux-short-form-item
              :label="$t('patient.create.form.step.phone_number.phone_number')"
              span="col-span-10 md:col-span-6"
            >
              <flux-input
                v-model:modelValue="form.phone_numbers[index].phone_number"
                type="tel"
                :prop="
                  'telephone_numbers.' + index.toString() + '.telephone_number'
                "
              />
            </flux-short-form-item>
            <flux-short-form-item span="col-span-2 md:hidden">
              <flux-button
                type="text"
                size="small"
                icon="fal fa-times"
                @click="removePhoneNumber(index)"
              />
            </flux-short-form-item>
            <flux-short-form-item
              :label="
                $t('patient.create.form.step.phone_number.phone_number_type')
              "
              span="col-span-6 md:col-span-5"
            >
              <flux-select
                v-model:modelValue="form.phone_numbers[index].phone_number_type"
              >
                <option
                  v-for="(phoneNumberType, index2) in phoneNumberTypes"
                  :key="'phonet' + index2.toString()"
                  :value="phoneNumberType"
                  :label="$t(`phone_number.type.${phoneNumberType}`).toString()"
                >
                  {{ $t(`phone_number.type.${phoneNumberType}`) }}
                </option>
              </flux-select>
            </flux-short-form-item>
            <flux-short-form-item span="col-span-1 hidden md:block">
              <flux-button
                type="text"
                size="small"
                icon="fal fa-times"
                @click="removePhoneNumber(index)"
              />
            </flux-short-form-item>
          </div>
          <div class="h-4" v-if="form.phone_numbers.length === 0" />
          <div class="col-span-12 -mt-4">
            <flux-button
              type="text"
              size="small"
              icon="fal fa-plus"
              @click="addPhoneNumber"
            >
              {{ $t("patient.create.form.step.phone_number.add_more") }}
            </flux-button>
          </div>
          <div
            class="contents"
            v-for="(email_address, index3) in form.email_addresses"
            :key="'email' + index3.toString()"
          >
            <flux-short-form-item
              :label="
                $t('patient.create.form.step.email_address.email_address')
              "
              span="col-span-10 md:col-span-6"
            >
              <EmailInput
                v-model:modelValue="form.email_addresses[index3].email_address"
                :prop="
                  'email_addresses.' + index3.toString() + '.email_address'
                "
              />
            </flux-short-form-item>
            <flux-short-form-item span="col-span-2 md:hidden">
              <flux-button
                type="text"
                icon="fal fa-times"
                size="small"
                @click="removeEmailAddress(index3)"
              />
            </flux-short-form-item>
            <flux-short-form-item
              :label="
                $t('patient.create.form.step.email_address.email_address_type')
              "
              span="col-span-6 md:col-span-5"
            >
              <flux-select
                v-model:modelValue="
                  form.email_addresses[index3].email_address_type
                "
              >
                <option
                  v-for="(emailAddressType, index4) in emailAddressTypes"
                  :key="'emailt' + index4.toString()"
                  :value="emailAddressType"
                  :label="
                    $t(`email_address.type.${emailAddressType}`).toString()
                  "
                >
                  {{ $t(`email_address.type.${emailAddressType}`) }}
                </option>
              </flux-select>
            </flux-short-form-item>
            <flux-short-form-item span="col-span-1 hidden md:block">
              <flux-button
                type="text"
                icon="fal fa-times"
                size="small"
                @click="removeEmailAddress(index3)"
              />
            </flux-short-form-item>
          </div>
          <div class="col-span-12 -mt-4">
            <flux-button
              type="text"
              size="small"
              icon="fal fa-plus"
              @click="addEmailAddress"
            >
              {{ $t("patient.create.form.step.email_address.add_more") }}
            </flux-button>
          </div>
        </flux-short-form>
        <flux-button-group class="justify-end">
          <flux-button :disabled="!isDirty" @click="reset" type="secondary">{{
            $t("general.cancel")
          }}</flux-button>
          <flux-submit-button
            :disabled="!isDirty"
            @click="save"
            type="primary"
            >{{ $t("general.save") }}</flux-submit-button
          >
        </flux-button-group>
      </flux-card>
    </flux-form>
  </div>
</template>

<script setup lang="ts">
import { apiClient } from "@/libraries/utils/axios";
import { onMounted, ref, reactive, toRef, toRaw, watch } from "vue";
import {
  EmailAddressType,
  emailAddressTypes,
  EmailAddressPersisted,
} from "../../models/EmailAddress";
import {
  PhoneNumberType,
  phoneNumberTypes,
  PhoneNumberPersisted,
} from "../../models/PhoneNumber";
import { useNotify } from "@/composables/notify";
import { $t } from "@/libraries/i18n";
import { usePatient, useUpdatePatient } from "@/composables/patient";
import { clone } from "@/libraries/utils/clone";
import EmailInput from "@/components/ui/Input/EmailInput.vue";

const { notify } = useNotify();

interface EmailAddressForm {
  id?: number;
  preferred: boolean;
  email_address: string;
  email_address_type: EmailAddressType;
}

interface PhoneNumberForm {
  id?: number;
  preferred: boolean;
  phone_number: string;
  phone_number_type: PhoneNumberType;
}

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient } = usePatient(toRef(props, "zisNumber"));
const { updatePatient } = useUpdatePatient(toRef(props, "zisNumber"));

const loading = ref(false);
const emit = defineEmits<{
  (e: "updated"): void;
}>();
const form: {
  phone_numbers: (PhoneNumberPersisted | PhoneNumberForm)[];
  email_addresses: (EmailAddressPersisted | EmailAddressForm)[];
  suppressed_email_types: string[];
} = reactive({
  phone_numbers: [],
  email_addresses: [],
  suppressed_email_types: [],
});

const suppressedEmailTypes = [
  { id: "appointment.reminder" },
  { id: "appointment.created" },
  { id: "appointment.updated" },
  { id: "appointment.deleted" },
] as const;

const initialState = ref<
  Object & {
    phone_numbers: (PhoneNumberPersisted | PhoneNumberForm)[];
    email_addresses: (EmailAddressPersisted | EmailAddressForm)[];
    suppressed_email_types: string[];
  }
>({ phone_numbers: [], email_addresses: [], suppressed_email_types: [] });

onMounted(() => {
  if (patient.value) {
    form.email_addresses = toRaw(patient.value.email_addresses);
    form.phone_numbers = toRaw(patient.value.phone_numbers);
    form.suppressed_email_types = toRaw(patient.value.suppressed_email_types);
    initialState.value = clone(form);
  }
});

const isDirty = ref(false);
watch(
  form,
  () => {
    isDirty.value = true;
  },
  { deep: true },
);

function save() {
  if (!patient.value) {
    throw new Error("no patient");
  }
  loading.value = true;

  // Find new PN's
  const created_promise: Promise<any>[] = form.phone_numbers
    .filter((pn) => !pn.id)
    .map((pn) => apiClient.post("/phone_numbers", pn));

  // Find updated PN's
  const updated = form.phone_numbers
    .filter((pn) => pn.id)
    .filter(
      (pn) =>
        !initialState.value.phone_numbers.some(
          (pn2) => pn2.phone_number === pn.phone_number,
        ),
    );

  // Update PN's
  const updated_promise: Promise<any>[] = updated.map((pn) =>
    apiClient.put("/phone_numbers/:id", pn, {
      params: { id: pn.id as number },
    }),
  );

  // Find deleted emails
  const deleted_phone_numbers = initialState.value.phone_numbers.filter(
    (pn) => !form.phone_numbers.some((pn2) => pn2.id === pn.id),
  );

  // Delete emails
  const deleted_phone_number_promise: Promise<any>[] =
    deleted_phone_numbers.map((pn) =>
      apiClient.delete("/phone_numbers/:id", {
        params: { id: pn.id as number },
      }),
    );

  // Find new emails
  const created_email_promise: Promise<any>[] = form.email_addresses
    .filter((email) => !email.id)
    .map((email) => apiClient.post("/email_addresses", email));

  // Find updated emails
  const updated_email = form.email_addresses
    .filter((email) => email.id)
    .filter(
      (email) =>
        !initialState.value.email_addresses.some(
          (email2) => email2.email_address === email.email_address,
        ),
    );

  // Update emails
  const updated_email_promise: Promise<any>[] = updated_email.map((email) =>
    apiClient.put("/email_addresses/:id", email, {
      params: { id: email.id as number },
    }),
  );

  // Find deleted emails
  const deleted_emails = initialState.value.email_addresses.filter(
    (email) => !form.email_addresses.some((email2) => email2.id === email.id),
  );

  // Delete emails
  const deleted_email_promise: Promise<any>[] = deleted_emails.map((email) =>
    apiClient.delete("/email_addresses/:id", {
      params: { id: email.id as number },
    }),
  );

  // Update suppressed_email_types
  const suppressed_email_types_promise: Promise<any> = updatePatient({
    suppressed_email_types: form.suppressed_email_types,
  });

  const promises = [
    created_promise,
    updated_promise,
    created_email_promise,
    updated_email_promise,
    deleted_email_promise,
    deleted_phone_number_promise,
    suppressed_email_types_promise,
  ].flat();
  const P = Promise.allSettled(promises);
  P.then(() => {
    notify({
      message: $t("patient.edit.contactinfo.success") as string,
      type: "success",
    });
  }).finally(() => {
    emit("updated");
    loading.value = false;
    initialState.value = clone(form);
  });
}

function addPhoneNumber() {
  form.phone_numbers.push({
    preferred: false,
    phone_number: "",
    phone_number_type: "HP" as PhoneNumberType,
    patient_zis_number: patient.value?.zis_number,
  } as PhoneNumberForm);
}

function addEmailAddress() {
  form.email_addresses.push({
    email_address: "",
    email_address_type: "HP" as EmailAddressType,
    preferred: false,
    patient_zis_number: patient.value?.zis_number,
  } as EmailAddressForm);
}

function removePhoneNumber(index: number) {
  form.phone_numbers.splice(index, 1);
}

function removeEmailAddress(index: number) {
  form.email_addresses.splice(index, 1);
}

function reset() {
  form.email_addresses = initialState.value.email_addresses;
  form.phone_numbers = initialState.value.phone_numbers;
}
</script>

<style lang="postcss">
.vertical-spacer {
  height: 1px;
  width: 100%;
  background: #e9e9e9;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
