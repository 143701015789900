<template>
  <div class="mx-auto w-full max-w-5xl">
    <flux-card-button-header title="Taken"> </flux-card-button-header>
    <div class="mt-4 flex flex-col gap-x-4 md:flex-row">
      <flux-floating-menu
        class="top-10 flex h-max md:sticky"
        menuClass="flex md:flex-col flex-row flex-wrap gap-x-2 w-screen md:w-full"
      >
        <flux-floating-menu-item
          class="w-max"
          @click="activeTab = 'myTasks'"
          :active="activeTab == 'myTasks'"
          >Mijn taken</flux-floating-menu-item
        >
        <flux-floating-menu-item
          class="w-fit"
          @click="activeTab = 'allTasks'"
          :active="activeTab == 'allTasks'"
          >Alle taken</flux-floating-menu-item
        >
        <flux-floating-menu-item
          class="w-fit"
          @click="activeTab = 'myCompletedTasks'"
          :active="activeTab == 'myCompletedTasks'"
          >Al mijn afgeronde taken</flux-floating-menu-item
        >
        <flux-floating-menu-item
          class="w-fit"
          @click="activeTab = 'completedTasks'"
          :active="activeTab == 'completedTasks'"
          >Alle afgeronde taken</flux-floating-menu-item
        >
        <flux-floating-menu-item
          class="w-fit"
          @click="activeTab = 'cancelledTasks'"
          :active="activeTab == 'cancelledTasks'"
          >Geannuleerde taken</flux-floating-menu-item
        >
      </flux-floating-menu>
      <div></div>
      <TaskTable
        v-if="activeTab == 'myTasks'"
        :patient="patient"
        @update="invalidateTasks"
        :tasks="myTasks"
      ></TaskTable>
      <TaskTable
        v-if="activeTab == 'allTasks'"
        :patient="patient"
        @update="invalidateTasks"
        :tasks="AllTasks"
      ></TaskTable>
      <TaskTable
        v-if="activeTab == 'myCompletedTasks'"
        :patient="patient"
        @update="invalidateTasks"
        :tasks="myCompletedTasks"
      ></TaskTable>
      <TaskTable
        v-if="activeTab == 'completedTasks'"
        :patient="patient"
        @update="invalidateTasks"
        :tasks="completedTasks"
      ></TaskTable>
      <TaskTable
        v-if="activeTab == 'cancelledTasks'"
        :patient="patient"
        @update="invalidateTasks"
        :tasks="cancelledTasks"
      ></TaskTable>
    </div>
  </div>
</template>
<script setup lang="ts">
import TaskTable from "@/components/Tasks/TasksTable.vue";
import { computed, PropType, ref } from "vue";
import { getUser } from "@/libraries/plugins/getUser";
import { Task } from "@/models/Task";
import { Patient } from "@/composables/patient";
import { useTasks } from "@/composables/myTasks";

const activeTab = ref("myTasks");

const props = defineProps({
  patient: {
    type: Object as PropType<Patient>,
    required: false,
  },
});

const { data: tasks, invalidate: invalidateTasks } = useTasks();

const myTasks = computed(
  () =>
    tasks.value?.filter(
      (t) =>
        t.assignee_id == getUser().id &&
        t.status !== "done" &&
        t.status !== "cancelled",
    ) ?? [],
);
const AllTasks = computed(
  () =>
    tasks.value?.filter(
      (t) => t.status !== "done" && t.status !== "cancelled",
    ) ?? [],
);
const completedTasks = computed(
  () => tasks.value?.filter((t) => t.status === "done") ?? [],
);
const myCompletedTasks = computed(
  () =>
    tasks.value?.filter(
      (t) => t.assignee_id == getUser().id && t.status === "done",
    ) ?? [],
);
const cancelledTasks = computed(
  () => tasks.value?.filter((t) => t.status === "cancelled") ?? [],
);
</script>
