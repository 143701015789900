<template>
  <flux-callout @action="clickAction" :type="type">
    <template #icon>
      <i
        :class="[
          'fas text-xl',
          type == 'error' || type == 'warning'
            ? 'fa-exclamation-triangle'
            : 'fa-question-circle text-slate-600',
        ]"
      ></i>
    </template>
    <template v-if="$slots.action" #action>
      <slot name="action"></slot>
    </template>
    <slot></slot>
  </flux-callout>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    type?: "error" | "info" | "warning";
  }>(),
  { type: "error" },
);

const emit = defineEmits<{
  (e: "action"): void;
}>();

function clickAction() {
  emit("action");
}
</script>
