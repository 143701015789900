import { useAgendaUserSelection } from "@/composables/agendaUserSelection";
import { getUser } from "@/libraries/plugins/getUser";
import store from "@/libraries/store";
import { CalendarDisplayMode } from "@/libraries/store/interfaces";
import {
  add,
  addDays,
  addWeeks,
  format,
  getDay,
  previousDay,
  startOfDay,
  subDays,
  subWeeks,
} from "date-fns";
import { ref } from "vue";
import { readonly } from "vue";
import { watch } from "vue";
import { computed } from "vue";

const date = ref<Date>(startOfDay(new Date()));
const days = ref<string[]>([]);
const displayMode = ref<CalendarDisplayMode>();

export function useCalendarNavigation() {
  const { setmaxSelectedUserIds } = useAgendaUserSelection();

  const calendarState = store.state.calendar;

  //Day of week, 0: sunday, 1: monday
  const weekStartDay = computed((): number => {
    const user = getUser();

    if (!user.calendar_settings?.start_of_week) {
      if (user.roles.some((role) => role.name === "front-desk")) {
        return getDay(date.value);
      }
      return 1;
    }

    switch (user.calendar_settings.start_of_week) {
      case "sunday":
        return 0;
      case "currentDay":
        return getDay(date.value);
      case "monday":
      default:
        return 1;
    }
  });

  const startDate = computed(() => {
    if (displayMode.value !== "week" && displayMode.value !== "5day") {
      return cloneDate(date.value);
    }

    const startOfWeek = getCurrentOrPreviousDay(date.value, weekStartDay.value);

    if (displayMode.value === "5day") {
      //Monday
      return getCurrentOrPreviousDay(date.value, 1);
    }

    return startOfWeek;
  });

  const startTimeInMinutes = readonly(ref(0));
  const endTimeInMinutes = readonly(ref(24 * 60));

  function setDate(newDate: Date) {
    date.value = newDate;
    setDays();
  }

  function setDateButKeepWeekStart(newDate: Date) {
    if (displayMode.value === "week" || displayMode.value === "5day") {
      setDate(getCurrentOrPreviousDay(newDate, weekStartDay.value));
      return;
    }
    setDate(newDate);
  }

  function setDateAndDisplayMode(
    newDate: Date,
    newDisplayMode: CalendarDisplayMode,
  ) {
    date.value = newDate;
    displayMode.value = newDisplayMode;
    setDays();
  }

  function setInitialDisplayMode() {
    if (displayMode.value) {
      return;
    }
    if (window.innerWidth < 768) {
      setDisplayMode("day");
      return;
    }
    const preferredDisplayMode =
      getUser().calendar_settings?.calendar_view ??
      (localStorage.getItem("lastUsedDisplayMode") as CalendarDisplayMode);
    setDisplayMode(preferredDisplayMode ?? "week");
  }

  function setDisplayMode(newDisplayMode: CalendarDisplayMode) {
    const oldDays = days.value;
    displayMode.value = newDisplayMode;

    if (
      newDisplayMode === "day" &&
      oldDays.includes(format(new Date(), "yyyy-MM-dd"))
    ) {
      setDate(startOfDay(new Date()));
    } else if (
      newDisplayMode === "week" &&
      oldDays.includes(format(new Date(), "yyyy-MM-dd")) &&
      getUser().calendar_settings?.start_of_week === "currentDay"
    ) {
      setDate(startOfDay(new Date()));
    } else {
      setDate(cloneDate(startDate.value));
    }

    localStorage.setItem("lastUsedDisplayMode", newDisplayMode);
    setmaxSelectedUserIds(newDisplayMode === "day" ? 8 : 4);

    calendarState.displayMode = newDisplayMode;
  }

  function setDays() {
    let nDays = 0;
    switch (displayMode.value) {
      case "week":
        nDays = 7;
        break;
      case "5day":
      case "dayPerWeek":
        nDays = 5;
        break;
      default:
        nDays = 1;
    }

    const interval = displayMode.value === "dayPerWeek" ? "weeks" : "days";

    const oldDays = days.value;
    const newDays: string[] = [];

    while (newDays.length < nDays) {
      const nextDate = add(cloneDate(date.value), {
        [interval]: newDays.length,
      });
      newDays.push(format(nextDate, "yyyy-MM-dd"));
    }

    days.value = newDays;
    //Sync days with old store for appointment fetching
    syncDaysInStore(oldDays, newDays);
  }

  function syncDaysInStore(oldDays: string[], newDays: string[]) {
    calendarState.days = newDays;
    if (!oldDays) {
      store.dispatch("calendar/getAppointmentsForCheckedUsers");
      return;
    }
    // If the new interval is not contained in the old one, get appointments.
    if (newDays.some((day) => !oldDays.includes(day))) {
      store.dispatch("calendar/getAppointmentsForCheckedUsers");
    } else {
      store.dispatch("calendar/filterAppointmentsByDate");
    }
  }

  function next() {
    switch (displayMode.value) {
      case "week":
      case "5day":
        setDate(addWeeks(startDate.value, 1));
        break;
      case "schedule":
      case "day":
      case "dayPerWeek":
        setDate(addDays(startDate.value, 1));
        break;
    }
    store.commit("calendar/deactivateAppointment");
    store.dispatch("calendar/getAppointmentsForCheckedUsers");
  }

  function previous() {
    switch (displayMode.value) {
      case "week":
      case "5day":
        setDate(subWeeks(startDate.value, 1));
        break;
      case "schedule":
      case "day":
      case "dayPerWeek":
        setDate(subDays(startDate.value, 1));
        break;
    }
    store.commit("calendar/deactivateAppointment");
    store.dispatch("calendar/getAppointmentsForCheckedUsers");
  }

  function goToToday() {
    setDate(startOfDay(new Date()));
    if (displayMode.value === "week") {
      setDate(getCurrentOrPreviousDay(date.value, weekStartDay.value));
    }
  }

  watch(
    () => calendarState.unpersistedAppointment?.start,
    (newStart) => {
      if (!newStart) {
        return;
      }
      updateDaysIfAppointmentIsOutOfRange(newStart.toDate());
    },
    { deep: true },
  );

  watch(
    () => calendarState.activeAppointmentId,
    (newId) => {
      const appointment = calendarState.appointments[newId];
      if (!appointment) {
        return;
      }
      updateDaysIfAppointmentIsOutOfRange(appointment.start.toDate());
    },
  );

  function updateDaysIfAppointmentIsOutOfRange(appointmentDate: Date) {
    if (days.value.includes(format(appointmentDate, "yyyy-MM-dd"))) {
      return;
    }
    setDate(appointmentDate);
  }

  return {
    date: readonly(date),
    days,
    displayMode: readonly(displayMode),
    endTimeInMinutes,
    startDate,
    startTimeInMinutes,
    goToToday,
    next,
    previous,
    setDate,
    setDateAndDisplayMode,
    setDateButKeepWeekStart,
    setDisplayMode,
    setInitialDisplayMode,
  };
}

function cloneDate(date: Date) {
  return new Date(date.getTime());
}

function getCurrentOrPreviousDay(date: Date, day: number) {
  if (getDay(date) === day) {
    return cloneDate(date);
  }
  return previousDay(cloneDate(date), day);
}
