import { ref } from "vue";

export type NotificationType = "default" | "success" | "error" | "warning";

export interface NotifyOptions {
  title?: string;
  message: string | { type: "component"; component: any; props: any };
  type?: NotificationType;
  duration?: number;
  showClose?: boolean;
  action?: {
    text: string;
    callback: Function;
  };
}

const defaultNotifyOptions = {
  type: "default",
  showClose: true,
  duration: 5000,
} as const;

const notifications = ref<Map<string, NotifyOptions>>(new Map());

export function useNotify() {
  const notify = (notificationProps: NotifyOptions) => {
    const uuid = crypto.randomUUID();
    notifications.value.set(uuid, {
      ...defaultNotifyOptions,
      ...notificationProps,
    });
  };

  const unmount = (uuid: string) => {
    notifications.value.delete(uuid);
  };

  return { notifications, notify, unmount };
}
