import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import {
  createSalutationTemplate,
  getSalutationTemplates,
  mutateSalutationTemplate,
  SalutationTemplateQueryKeys,
} from "@/queries/salutationTemplateQuery";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { Ref, computed } from "vue";

export function useSalutationTemplates() {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: SalutationTemplateQueryKeys.salutationTemplates(),
    queryFn: () => getSalutationTemplates(),
    enabled: hasFeatureFlag("email-templates"),
    refetchOnWindowFocus: false,
  });

  const creation = useMutation({
    mutationFn: (payload: any) => createSalutationTemplate(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SalutationTemplateQueryKeys.salutationTemplates(),
      });
    },
  });

  const mutation = useMutation({
    mutationFn: (payload: { uuid: string; payload: any }) =>
      mutateSalutationTemplate(payload.uuid, payload.payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SalutationTemplateQueryKeys.salutationTemplates(),
      });
    },
  });

  return {
    ...query,
    createSalutationTemplate: (payload: any) =>
      creation.mutateAsync(payload, {}),
    mutateSalutationTemplate: (uuid: string, payload: any) =>
      mutation.mutateAsync({ uuid: uuid, payload: payload }),
  };
}

export function useSalutationTemplate(salutationTemplate: Ref<string>) {
  const { mutateSalutationTemplate, data } = useSalutationTemplates();

  return {
    data: computed(
      () => data.value?.filter((x) => x.uuid === salutationTemplate.value)[0],
    ),
    mutateSalutationTemplate: (payload: any) =>
      mutateSalutationTemplate(salutationTemplate.value, payload),
  };
}
