import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { Ref } from "vue";

export interface Tab {
  name: string;
  label: string;
  badgeColor: MaybeRef<string | undefined>;
  disabled: MaybeRef<boolean>;
}
export class TabManager {
  tabs: Tab[] = [];

  activeTabName: string | null = null;

  registerTab(tab: Tab) {
    this.tabs.push(tab);
    if (this.activeTabName === null) {
      this.activeTabName = tab.name;
    }
  }

  setActiveTabName(name: string) {
    this.activeTabName = name;
  }

  unregisterTab(name: string) {
    this.tabs = this.tabs.filter((tab) => tab.name !== name);
  }
}
