import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse } from "@/libraries/utils/errorHandling";
import { z } from "zod";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { QueryKeyFactory } from "./querykeys";
import {
  SalutationTemplate,
  SalutationTemplateSchema,
} from "@/models/SalutationTemplate";

export async function getSalutationTemplates(): Promise<SalutationTemplate[]> {
  const { data } = await apiClient.get("salutation_templates");

  return handledZodParse({
    schema: z.array(SalutationTemplateSchema),
    input: data["salutation_templates"],
    notifyUser: false,
  });
}

export async function mutateSalutationTemplate(
  salutationTemplateUuid: MaybeRef<string>,
  payload: any,
) {
  await apiClient.patch(
    "/salutation_templates/:salutation_template_uuid",
    payload,
    {
      params: {
        salutation_template_uuid: salutationTemplateUuid,
      },
    },
  );
}

export async function createSalutationTemplate(payload: any) {
  await apiClient.post("/salutation_templates/", payload, {});
}

export const SalutationTemplateQueryKeys = {
  salutationTemplates: () => ["salutationTemplates"] as const,
} as const satisfies QueryKeyFactory;
