<template>
  <flux-select v-model="selectedDivision" @update:model-value="onInput">
    <option :value="undefined" label="Geen">Geen</option>
    <optgroup
      v-for="company in companies ?? []"
      :label="company.name"
      :key="company.uuid"
    >
      <option
        v-for="division in company.company_divisions"
        :value="division"
        :label="`${company.name} › ${division.name}`"
      >
        {{ company.name }} › {{ division.name }}
      </option>
      <option v-if="company.company_divisions.length === 0" disabled>
        Geen divisies toegevoegd
      </option>
    </optgroup>
  </flux-select>
</template>

<script lang="ts" setup>
import { useCompanies } from "@/composables/companies";
import { CompanyListEntry } from "@/queries/companies/companies";
import { onMounted, ref, watch } from "vue";

const emit = defineEmits<{
  (
    e: "input:value",
    value?: CompanyListEntry["company_divisions"][number],
  ): void;
  (e: "input:id", value?: string): void;
}>();
const props = defineProps<{
  value?: CompanyListEntry["company_divisions"][number];
  id?: string;
}>();

const { data: companies } = useCompanies();

const selectedDivision = ref<
  CompanyListEntry["company_divisions"][number] | undefined
>(undefined);

watch(
  () => props.id,
  (id) => {
    selectedDivision.value = findDivisionById(id);
    onInput(selectedDivision.value);
  },
  { immediate: true },
);

function findDivisionById(id?: string) {
  return companies.value
    ?.flatMap((c) => c.company_divisions)
    .find((d) => d.uuid === id);
}

function onInput(
  companyDivision?: CompanyListEntry["company_divisions"][number],
): void {
  emit("input:value", companyDivision);
  emit("input:id", companyDivision?.uuid);
}
</script>
