import { SearchRequestPayload } from "@/interfaces";
import { AxiosInstance } from "axios";
import { PatientRepository, searchResultSchema } from "./patientRepository";
import { apiClient } from "@/libraries/utils/axios";
import { TypedAxiosInstance } from "restyped-axios";
import { FluxApi } from "@/apis/flux";
import { handleErrors } from "../utils/errorHandling";

export class PatientRepositoryUsingApi implements PatientRepository {
  #api: TypedAxiosInstance<FluxApi>;

  constructor() {
    this.#api = apiClient;
  }

  async search(input: string) {
    if (input === "") {
      return [];
    }

    const payload: SearchRequestPayload = this.filterInput(input);

    return await this.#api
      .post("/search/patients", payload)
      .then((res) => searchResultSchema.parse(res.data).patients);
  }

  async activatePatient(zis_number: number) {
    await apiClient
      .patch(`/patients/${zis_number}/activate`)
      .catch((error) => handleErrors({ error }));
  }

  async deactivatePatient(zis_number: number) {
    await apiClient
      .patch(`/patients/${zis_number}/deactivate`)
      .catch((error) => handleErrors({ error }));
  }

  /**
   * Detect if the input contains a date of birth and return a SearchRequestPayload
   *
   * @param input
   * @returns
   */
  filterInput(input: string): SearchRequestPayload {
    let year: number | undefined = undefined;
    const yearNow = new Date().getFullYear();

    if (
      input.match(/(\d{4})/g) &&
      !input.match(/(\d{5})/g) &&
      !input.match(/(-\d{4})/g) &&
      1912 < parseInt(input.match(/(\d{4})/g)![0]) &&
      parseInt(input.match(/(\d{4})/g)![0]) <= yearNow
    ) {
      year = parseInt(input.match(/(\d{4})/g)![0]);
      input = input.replace(year.toString(), "");
    }

    const num = input
      .matchAll(
        /(?<day>\d{1,2})(?:[-\/.\s]*(?<month>\d{1,2})(?:[-\/.\s]*(?<year>\d{4}|\d{2}(?!\d)))?)?/g,
      )
      .next().value?.groups;
    const name = input.replace(
      /(?<day>\d{1,2})(?:[-\/.\s]*(?<month>\d{1,2})(?:[-\/.\s]*(?<year>\d{4}|\d{2}(?!\d)))?)?/g,
      "",
    );

    if (num?.year && num.year.toString().length == 2) {
      if (yearNow > 2000 + parseInt(num.year)) {
        year = parseInt("20" + num.year);
      } else {
        year = parseInt("19" + num.year);
      }
    }

    if (input.match(/(^#\d{8})/)) {
      const zis_number = input.match(/(^#\d{8})/g)![0].replace("#", "");
      return {
        zis_number,
      };
    }

    return {
      ...(name ? { name: name.trim() } : {}),
      ...(num || year
        ? {
            date_of_birth: {
              year: year ?? (num?.year ? parseInt(num.year) : undefined),
              month: num?.month ? parseInt(num.month) : undefined,
              day: num?.day ? parseInt(num.day) : undefined,
            },
          }
        : {}),
    };
  }
}
