import EncryptedStorage, {
  EncryptedStoredData,
  KeyStore,
} from "./encryptedStorage";

export default class EncryptedStorageManager implements FluxStorageManager {
  private underlyingStorageManager: FluxStorageManager;
  private encryptionKeyId: string;
  private keyStore: KeyStore;

  constructor(
    underlyingStorageManager: FluxStorageManager,
    encryptionKeyId: string,
    keyStore: KeyStore,
  ) {
    this.underlyingStorageManager = underlyingStorageManager;
    this.encryptionKeyId = encryptionKeyId;
    this.keyStore = keyStore;
  }

  public async getScopedInstance<K extends IDBValidKey, T>(
    scopeName: string,
    userUuid: string,
  ): Promise<FluxStorage<K, T>> {
    return new EncryptedStorage<K, T>(
      await this.underlyingStorageManager.getScopedInstance<
        ArrayBuffer,
        EncryptedStoredData
      >("encrypted-" + scopeName, userUuid),
      this.encryptionKeyId,
      this.keyStore,
    );
  }
}
