import { getUser } from "@/libraries/plugins/getUser";
import store from "@/libraries/store";
import { Role } from "@/models/Role";
import { User } from "@/models/User";
import debounce from "debounce";
import { ref } from "vue";

const selectedUserIds = ref<number[]>([]);
const maxSelectedUserIds = ref(4);
const isAwaitingDebounce = ref(false);
const cursorActive = ref(false);

export function useAgendaUserSelection() {
  /**
   * Gets checked users from local storage. If local storage item is not an array
   * of numbers or does not exist, it is replaced with an array containing only the user id.
   */
  function setInitialSelectedUserIds() {
    const selectedUserIdsString = localStorage.getItem(
      "calendar.selectedUserIds",
    );

    if (!selectedUserIdsString) {
      setDefaultSelectedUserIds();
      return;
    }

    const selectedUserIdsParsed = JSON.parse(selectedUserIdsString);

    if (
      !Array.isArray(selectedUserIdsParsed) ||
      !selectedUserIdsParsed.every((userId) => Number.isInteger(userId))
    ) {
      setDefaultSelectedUserIds();
      return;
    }

    setSelectedUserIds(selectedUserIdsParsed);
  }

  function setDefaultSelectedUserIds() {
    setSelectedUserIds([getUser().id]);
  }

  function setSelectedUserIds(userIds: number[]) {
    const noNewUserSelected = userIds.every((id) =>
      selectedUserIds.value.includes(id),
    );
    if (selectedUserIds.value.length === userIds.length && noNewUserSelected) {
      return;
    }
    selectedUserIds.value = userIds;
    localStorage.setItem(
      "calendar.selectedUserIds",
      JSON.stringify(selectedUserIds.value),
    );
    store.state.calendar.user_ids = selectedUserIds.value;
    if (noNewUserSelected) {
      store.dispatch("calendar/filterAppointmentsByUser");
    } else {
      changedAgendas();
    }
  }

  function setmaxSelectedUserIds(newMax: number) {
    maxSelectedUserIds.value = newMax;
    setSelectedUserIds(
      selectedUserIds.value.slice(0, maxSelectedUserIds.value),
    );
  }

  function toggleUser(userId: number) {
    cursorActive.value = false;
    if (selectedUserIds.value.includes(userId)) {
      setSelectedUserIds(selectedUserIds.value.filter((id) => id !== userId));
      return;
    }
    if (selectedUserIds.value.length < maxSelectedUserIds.value) {
      setSelectedUserIds([...selectedUserIds.value, userId]);
    }
  }

  function clearAllSelectedUserIds() {
    if (selectedUserIds.value.length === 0) {
      return;
    }
    setSelectedUserIds([]);
  }

  function next4Users(
    direction: "next" | "prev" = "next",
    filteredUsers: User[],
  ) {
    const filteredDocterUsers = filteredUsers.filter((a) =>
      a.roles?.some((r: Role) => r.name === "doctor"),
    );
    const step = selectedUserIds.value.length;
    let startIndex = 0;

    if (cursorActive.value) {
      selectNextUsers(filteredDocterUsers, step, direction);
      changedAgendasDebounced();
      return;
    }

    const firstUsersToSelect = filteredDocterUsers
      .slice(startIndex, startIndex + step)
      .map((user) => user.id);

    cursorActive.value = true;
    if (
      selectedUserIds.value.some(
        (userId) => !firstUsersToSelect.includes(userId),
      )
    ) {
      setSelectedUserIds(firstUsersToSelect);
    } else {
      selectNextUsers(filteredDocterUsers, step, direction);
    }
  }

  function selectNextUsers(
    filteredDocterUsers: User[],
    step: number,
    direction: "prev" | "next",
  ) {
    const currentIndex = filteredDocterUsers.findIndex(
      (user) => user.id === selectedUserIds.value[0],
    );
    let startIndex = 0;

    if (direction === "next") {
      startIndex = (currentIndex + step) % filteredDocterUsers.length;
    } else {
      startIndex =
        (currentIndex - step + filteredDocterUsers.length) %
        filteredDocterUsers.length;
    }

    const endIndex = (startIndex + step) % filteredDocterUsers.length;

    if (startIndex < endIndex) {
      setSelectedUserIds(
        filteredDocterUsers.slice(startIndex, endIndex).map((user) => user.id),
      );
    } else {
      setSelectedUserIds([
        ...filteredDocterUsers
          .slice(startIndex, filteredDocterUsers.length)
          .map((user) => user.id),
        ...filteredDocterUsers.slice(0, endIndex).map((user) => user.id),
      ]);
    }
  }

  function selectSoleUser(selectableUsers: User[], userId?: number) {
    if (userId) {
      setSelectedUserIds([userId]);
    } else if (selectableUsers.length === 1) {
      setSelectedUserIds([selectableUsers[0].id]);
    }
  }

  const changedAgendasDebouncedAsync = debounce(changedAgendas, 1000);

  function changedAgendas() {
    isAwaitingDebounce.value = false;
    store.dispatch("calendar/getAppointmentsForCheckedUsers");
  }

  function changedAgendasDebounced() {
    isAwaitingDebounce.value = true;
    changedAgendasDebouncedAsync();
  }

  return {
    selectedUserIds,
    cursorActive,
    isAwaitingDebounce,
    maxSelectedUserIds,
    clearAllSelectedUserIds,
    next4Users,
    selectSoleUser,
    setSelectedUserIds,
    setInitialSelectedUserIds,
    setmaxSelectedUserIds,
    toggleUser,
    changedAgendasDebounced,
  };
}
