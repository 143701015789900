import {
  getAutosavePrivateKey,
  getAutosavePublicKey,
} from "@/queries/autosaveKeys";
import { KeyStore } from "./encryptedStorage";

export default class ApiKeyStore implements KeyStore {
  private pubKeyCache = new Map<string, CryptoKey>();
  private privKeyCache = new Map<string, CryptoKey>();

  private base64StringToArrayBuffer(b64str: string): ArrayBuffer {
    var byteStr = atob(b64str);
    var bytes = new Uint8Array(byteStr.length);
    for (var i = 0; i < byteStr.length; i++) {
      bytes[i] = byteStr.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private convertPemToBinary(pem: string): ArrayBuffer {
    var lines = pem.split("\n");
    var encoded = "";
    for (var i = 0; i < lines.length; i++) {
      if (
        lines[i].trim().length > 0 &&
        lines[i].indexOf("-----BEGIN RSA PRIVATE KEY-----") < 0 &&
        lines[i].indexOf("-----BEGIN RSA PUBLIC KEY-----") < 0 &&
        lines[i].indexOf("-----BEGIN PUBLIC KEY-----") < 0 &&
        lines[i].indexOf("-----END PUBLIC KEY-----") < 0 &&
        lines[i].indexOf("-----BEGIN PRIVATE KEY-----") < 0 &&
        lines[i].indexOf("-----END PRIVATE KEY-----") < 0 &&
        lines[i].indexOf("-----END RSA PRIVATE KEY-----") < 0 &&
        lines[i].indexOf("-----END RSA PUBLIC KEY-----") < 0
      ) {
        encoded += lines[i].trim();
      }
    }
    return this.base64StringToArrayBuffer(encoded);
  }

  public async getDecryptionKey(id: string): Promise<CryptoKey> {
    const cachedKey = this.privKeyCache.get(id);
    if (cachedKey !== undefined) {
      return cachedKey;
    }
    const keyData = await getAutosavePrivateKey(id);
    const key = await crypto.subtle.importKey(
      "pkcs8",
      this.convertPemToBinary(keyData),
      { name: "RSA-OAEP", hash: { name: "SHA-256" } },
      true,
      ["decrypt"],
    );
    this.privKeyCache.set(id, key);
    return key;
  }

  public async getEncryptionKey(id: string): Promise<CryptoKey> {
    const cachedKey = this.pubKeyCache.get(id);
    if (cachedKey !== undefined) {
      return cachedKey;
    }
    const keyData = await getAutosavePublicKey(id);

    const key = await crypto.subtle.importKey(
      "spki",
      this.convertPemToBinary(keyData),
      { name: "RSA-OAEP", hash: { name: "SHA-256" } },
      true,
      ["encrypt"],
    );
    this.pubKeyCache.set(id, key);
    return key;
  }
}
