<template>
  <div class="contents">
    <Notifications />
    <Confirm
      :visible="visible"
      :onConfirm="props.onConfirm"
      :onCancel="props.onCancel"
      :confirmButtonText="props.confirmButtonText"
      :cancelButtonText="props.cancelButtonText"
      :canCancel="props.canCancel"
      :customHeader="props.customHeader"
      :customBody="props.customBody"
      :type="props.type"
    />
    <div class="flex min-h-screen items-center justify-around">
      <div
        class="box-border w-full max-w-md rounded-lg border border-gray-300 px-8 py-12 pb-20"
      >
        <div>
          <h2 class="text-center text-3xl font-normal">
            <img class="h-20 w-20" src="@/assets/images/logo.svg" />
          </h2>
        </div>

        <flux-form v-flux-loading="loading">
          <h3 class="mb-2 text-base font-normal" v-if="user">
            {{ user ? user.first_names + " " + user.surname : "" }},
            {{ $t("set_password.please_set_password") }}
          </h3>
          <flux-input-box :label="$t('set_password.email_address')">
            <flux-input
              v-model:modelValue="credentials.email_address"
              :placeholder="$t('set_password.email_address')"
              :disabled="true"
            ></flux-input>
          </flux-input-box>
          <!-- Validation errors -->
          <div class="mb-2 mt-4" v-if="errors">
            <ValidationErrors :errors="errors"></ValidationErrors>
          </div>
          <flux-input-box
            class="mt-4"
            :label="$t('set_password.first_password')"
          >
            <flux-input
              v-model:modelValue="credentials.first_password"
              :disabled="inputsDisabled"
              type="password"
              prop="first_password"
              @blur="validatePassword()"
              @input="
                validatePassword(passwordValidator.error);
                validateRepeatPassword(repeatPasswordValidator.error);
              "
              :error="passwordValidator.error"
            />
          </flux-input-box>
          <div
            class="mt-2 h-4 text-xs text-red-500"
            v-if="passwordValidator.error"
          >
            {{ passwordValidator.message }}
          </div>
          <flux-input-box
            class="mt-4"
            :label="$t('set_password.second_password')"
          >
            <flux-input
              v-model:modelValue="credentials.second_password"
              :disabled="inputsDisabled"
              type="password"
              prop="second_password"
              @blur="validateRepeatPassword()"
              @change="validateRepeatPassword(repeatPasswordValidator.error)"
              :error="repeatPasswordValidator.error"
            />
          </flux-input-box>
          <div
            class="mt-2 h-4 text-xs text-red-500"
            v-if="repeatPasswordValidator.error"
          >
            {{ repeatPasswordValidator.message }}
          </div>
          <div class="mt-4 text-right">
            <flux-submit-button :disabled="inputsDisabled" @click="submit()">{{
              $t("set_password.submit")
            }}</flux-submit-button>
          </div>
        </flux-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ValidationErrors from "./ValidationErrors.vue";
import { authModule } from "./../auth";
import { apiClient } from "../libraries/utils/axios";
import { User } from "../models/User";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { useConfirm } from "@/composables/confirm";
import { ref } from "vue";
import store from "@/libraries/store";
import { $t } from "@/libraries/i18n";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { onMounted } from "vue";
import Notifications from "./ui/Notifications.vue";
import Confirm from "./ui/Confirm.vue";
import { useNotify } from "@/composables/notify";

const { confirm } = useConfirm();

const { notify } = useNotify();
const router = useRouter();

const { visible, props } = useConfirm();
const route = useRoute();

const healthcareProvider = ref<{ name: string }>({ name: "" });
const user = ref<User>();

const credentials = ref({
  email_address: "",
  first_password: "",
  second_password: "",
});

const loading = ref(false);

const errors = ref<MessageBag>();

const passwordValidator = ref({
  error: false,
  message: "",
});

const repeatPasswordValidator = ref({
  error: false,
  message: "",
});

const inputsDisabled = ref(false);

onMounted(() => {
  if (!route.query.email_address || !route.query.token) {
    router.push("error");
  }
  credentials.value.email_address = route.query.email_address as string;
  get_reset();
});

async function submit() {
  const payload = {
    token: route.query.token as string,
    email: credentials.value.email_address,
    password: credentials.value.first_password,
    password_confirmation: credentials.value.second_password,
  };
  try {
    const res = await apiClient.post("/resetpassword", payload);
    if (!!res.data.success) {
      const auth = authModule(store);
      confirm({
        message: $t("set_password.success.message") as string,
        title: $t("set_password.success.title") as string,
      }).then((response) => {
        if (response) {
          auth
            .login({
              username: payload.email,
              password: payload.password,
            })
            .then(() => router.push("/"));
        }
      });
    }
  } catch (err) {
    handleError(err);
  }
}

function handleError(err: any) {
  if (!err.response) {
    throw err;
  }
  const { status, data } = err.response;

  if (status == 422) {
    errors.value = new MessageBag(data.errors);
  }
  if (status == 400) {
    errors.value = new MessageBag(data.errors);
  }
}

function validatePassword(blurOrGreedy = true) {
  if (!blurOrGreedy) {
    return;
  }

  if (credentials.value.first_password.length < 6) {
    passwordValidator.value.error = true;
    passwordValidator.value.message = $t(
      "set_password.at_least_6_chars",
    ) as string;
  } else {
    passwordValidator.value.error = false;
    passwordValidator.value.message = "";
  }
}

function validateRepeatPassword(blurOrGreedy = true) {
  if (!blurOrGreedy) {
    return;
  }

  if (credentials.value.first_password !== credentials.value.second_password) {
    repeatPasswordValidator.value.error = true;
    repeatPasswordValidator.value.message = $t(
      "set_password.passwords_dont_match",
    ) as string;
  } else {
    repeatPasswordValidator.value.error = false;
    repeatPasswordValidator.value.message = "";
  }
}

function get_reset() {
  loading.value = true;
  apiClient
    .get("/resetpassword", {
      params: {
        email: route.query.email_address as string,
        token: route.query.token as string,
      },
    })
    .then((res) => {
      if (!res.data.token_is_valid) {
        notify({
          type: "error",
          title: "Kan wachtwoord niet resetten",
          message: $t("set_password.invalid_token") as string,
          duration: Infinity,
          showClose: true,
        });
        inputsDisabled.value = true;
      } else {
        healthcareProvider.value.name = res.data.healthcare_provider.name;
        user.value = res.data.user;
      }
    })
    .catch(() => {
      notify({
        type: "error",
        title: "Kan wachtwoord niet resetten",
        message: $t("set_password.invalid_token") as string,
      });
    })
    .finally(() => (loading.value = false));
}
</script>
