import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse } from "@/libraries/utils/errorHandling";
import { z } from "zod";
import { PatientTitleSchema, PatientTitle } from "@/models/PatientTitle";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { QueryKeyFactory } from "./querykeys";

export async function getPatientTitles(): Promise<PatientTitle[]> {
  const { data } = await apiClient.get("patient_titles");

  return handledZodParse({
    schema: z.array(PatientTitleSchema),
    input: data["patient_titles"],
    notifyUser: false,
  });
}

export async function mutatePatientTitle(
  patientTitleUuid: MaybeRef<string>,
  payload: any,
) {
  await apiClient.patch("/patient_titles/:patient_title_uuid", payload, {
    params: {
      patient_title_uuid: patientTitleUuid,
    },
  });
}

export async function createPatientTitle(payload: any) {
  await apiClient.post("/patient_titles/", payload, {});
}

export const PatientTitlesQueryKeys = {
  patientTitles: () => ["patientTitles"] as const,
} as const satisfies QueryKeyFactory;
