<template>
  <div v-flux-loading="loading">
    <flux-long-form>
      <flux-long-form-item
        v-if="
          hasFeatureFlag('invoice-generation') &&
          hasFeatureFlag('insurer-contracts')
        "
        :title="$t('patient.edit.invoices.default_invoice_type.title')"
        :description="
          $t('patient.edit.invoices.default_invoice_type.description')
        "
      >
        <div class="items-left my-auto ml-6 flex flex-col gap-y-3">
          <flux-radio-button-small
            v-model:modelValue="form.default_invoice_type"
            label="Automatisch"
            :optionValue="null"
            key="1"
          />
          <flux-radio-button-small
            v-model:modelValue="form.default_invoice_type"
            label="Voor patiënt"
            optionValue="patient.pm304"
            key="2"
          />
          <flux-radio-button-small
            v-model:modelValue="form.default_invoice_type"
            label="Voor verzekeraar"
            optionValue="insurer.partial.pm304"
            key="3"
          />
        </div>
      </flux-long-form-item>
    </flux-long-form>
  </div>
</template>

<script lang="ts" setup>
import { $t } from "@/libraries/i18n";
import { useNotify } from "@/composables/notify";
import { debounce } from "debounce";
import { ref, toRef, watch } from "vue";
import { PatientInvoiceSettings } from "../../models/Patient";
import { usePatient, useUpdatePatient } from "@/composables/patient";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

const { notify } = useNotify();

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient } = usePatient(toRef(props, "zisNumber"));
const { updatePatient: mutate } = useUpdatePatient(toRef(props, "zisNumber"));

const form = ref<PatientInvoiceSettings>({
  default_invoice_type: null,
});

const loading = ref(false);

watch(form.value, () => {
  if (
    form.value.default_invoice_type !==
    patient.value?.invoice_settings?.default_invoice_type
  ) {
    updatePatientDebounced();
  }
});

watch(
  patient,
  () => {
    if (!patient.value) return;
    if (patient.value.invoice_settings) {
      form.value.default_invoice_type =
        patient.value.invoice_settings.default_invoice_type ?? null;
    }
  },
  { immediate: true, deep: true },
);

const updatePatientDebounced = debounce(updatePatient, 500);

async function updatePatient() {
  try {
    loading.value = true;
    await mutate({
      invoice_settings: form.value,
    });
    notify({
      type: "success",
      message: $t("admin.settings.saved.succes"),
    });
  } catch {
    notify({
      type: "error",
      message: $t("admin.settings.saved.failure"),
    });
  } finally {
    loading.value = false;
  }
}
</script>
