export interface Person {
  first_names?: string;
  surname?: string;
  surname_prefix?: string;
  maiden_name?: string;
  maiden_name_prefix?: string;
  fullname?: string;
  nickname?: string;
  initials?: string;
  deceased?: boolean;
}

function addDeceasedIcon<T extends string | undefined>(
  name: T,
  person: Person,
): T {
  if (name === undefined) {
    return name;
  }
  if (person.deceased) {
    return (name + " †") as T;
  }
  return name;
}

/**
 * Returns first names with surname and maiden name.
 */
export const fullName = (person?: Person) => {
  if (!person) {
    return "Unknown person";
  }

  if (person.fullname && person.fullname.length > 0) {
    return addDeceasedIcon(person.fullname, person);
  }
  let fullSurname = person.surname;

  if (person.surname_prefix) {
    fullSurname = person.surname_prefix + " " + fullSurname;
  }

  if (person.maiden_name) {
    let maidenName = person.maiden_name;
    if (person.maiden_name_prefix) {
      maidenName = person.maiden_name_prefix + " " + maidenName;
    }
    fullSurname = fullSurname + "-" + maidenName;
  }

  let nickname = "";
  if (
    person.nickname &&
    person.nickname !== person.first_names?.split(" ")[0]
  ) {
    nickname = " (" + person.nickname + ")";
  }

  return addDeceasedIcon(
    person.first_names + nickname + " " + fullSurname,
    person,
  );
};

export const normalName = (person?: Person) => {
  if (!person) {
    return "Unknown person";
  }
  if (person.first_names === undefined) {
    return addDeceasedIcon(person.surname, person);
  }
  const firstName = person.first_names.split(" ")[0] + " ";
  let prefix = "";

  if (person.surname_prefix) {
    prefix = person.surname_prefix + " ";
  }
  return firstName + prefix + person.surname;
};

export const casualName = (person?: Person) => {
  if (!person) {
    return "Unknown person";
  }
  let firstName = "";
  let prefix = "";
  let lastName = "";

  if (person.nickname) {
    firstName = person.nickname + " ";
  } else if (person.first_names) {
    firstName = person.first_names.split(" ")[0] + " ";
  } else {
    firstName = person.initials ? person.initials + " " : "";
  }

  if (person.surname) {
    lastName = person.surname;
    prefix = person.surname_prefix ? person.surname_prefix + " " : "";
  } else if (person.maiden_name) {
    prefix = person.maiden_name_prefix ? person.maiden_name_prefix + " " : "";
    lastName = person.maiden_name;
  }
  return firstName + prefix + lastName;
};

/**
 * Returns initials name
 */
export const fullNameInitials = (person?: Person) => {
  if (!person) {
    return "Unknown person";
  }

  let fullSurname = person.surname;

  if (person.surname_prefix) {
    fullSurname = person.surname_prefix + " " + fullSurname;
  }

  if (person.maiden_name) {
    let maidenName = person.maiden_name;
    if (person.maiden_name_prefix) {
      maidenName = person.maiden_name_prefix + " " + maidenName;
    }
    fullSurname = fullSurname + "-" + maidenName;
  }

  if (person.initials) {
    return person.initials + " " + fullSurname;
  }

  const initials = person.first_names
    ?.split(" ")
    .map((name) => name[0].toUpperCase() + ".");
  return initials ? initials + " " + fullSurname : fullSurname;
};

export enum FormatName {
  fullName = "fullName",
  fullNameInitials = "fullNameInitials",
}
