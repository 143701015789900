import { InjectionKey, onBeforeUnmount, onMounted, Ref, ref } from "vue";

type RegistryValue = {
  autoClose: boolean;
  button: Ref;
  menu: Ref;
};

export function useDropdownManager() {
  const registry: Map<Symbol, RegistryValue> = new Map();

  const openDropdown = ref<Symbol>();

  const register = (
    sym: Symbol,
    { autoClose, button, menu }: RegistryValue,
  ) => {
    registry.set(sym, { autoClose, button, menu });
  };

  const unregister = (sym: Symbol) => registry.delete(sym);

  const open = (sym: Symbol) => {
    openDropdown.value = sym;
  };
  const close = (sym: Symbol) => {
    if (openDropdown.value !== sym) {
      return;
    }
    openDropdown.value = undefined;
  };

  const toggle = (sym: Symbol) => {
    if (openDropdown.value === sym) {
      close(sym);
    } else {
      open(sym);
    }
  };

  onMounted(() => window.addEventListener("click", handleWindowClick));
  onBeforeUnmount(() => window.removeEventListener("click", handleWindowClick));

  function handleWindowClick(ev: MouseEvent) {
    const target = ev.target;

    if (target === null || !(target instanceof Node)) {
      return;
    }

    for (const key of registry.keys()) {
      checkAndHandleClick(key, target);
    }
  }

  function checkAndHandleClick(sym: Symbol, target: Node) {
    const data = registry.get(sym);
    if (!data) {
      return;
    }

    const clickedButton = data.button.value.contains(target);
    if (clickedButton) {
      toggle(sym);
      return;
    }

    const clickedMenu = data.menu.value.contains(target);
    if (clickedMenu) {
      if (data.autoClose) {
        close(sym);
      }
      return;
    }

    close(sym);
  }

  function handleButtonHotkeyDown(sym: Symbol) {
    const data = registry.get(sym);
    if (!data) {
      return;
    }

    toggle(sym);
  }

  function handleMenuHotkeyDown(sym: Symbol) {
    const data = registry.get(sym);
    if (!data) {
      return;
    }

    if (data.autoClose) {
      close(sym);
    }
  }

  /* It may be needed to stop the propagation of a click event in a dropdown
   * to prevent it from triggering a different action. This means that the
   * event is not registered on the window. This function should be called
   * directly in that case.
   */
  function handleStoppedClick(sym: Symbol, target: Node) {
    checkAndHandleClick(sym, target);
  }

  return {
    openDropdown,
    register,
    unregister,
    handleStoppedClick,
    handleButtonHotkeyDown,
    handleMenuHotkeyDown,
    open,
    close,
  };
}

export const dropdownManagerKey = Symbol() as InjectionKey<
  ReturnType<typeof useDropdownManager>
>;
