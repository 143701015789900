import Vue from "vue";
import { Feature } from "@/models/Feature";
import { Permission } from "@/models/Permission";
import { User } from "@/models/User";
import { Role } from "@/models/Role";
import { UserFromSync } from "../store/interfaces";
import { getUser } from "@/libraries/plugins/getUser";

export function hasFeatureFlag(
  feature: string | Feature,
  user?: UserFromSync,
): boolean {
  const featureName = typeof feature == "string" ? feature : feature.name;

  const foundFeature = (user ?? getUser()).healthcare_provider.features.find(
    ({ name }) => name == featureName,
  );

  return foundFeature?.active ?? false;
}

export const install = (vue: any) => {
  // (vue.prototype as Vue)
  vue.config.globalProperties.userHasPermission = function (
    user: User & { roles: Role[] },
    permission: string | Permission,
  ): boolean {
    if (typeof permission !== "string") {
      permission = permission.name;
    }

    if (!this.store.state.roles) {
      return false;
    }

    return this.store.state.roles.some((role: any) => {
      const roleHasPermission = role.permissions.some(
        (rolePermission: Permission) => {
          return rolePermission.name === permission;
        },
      );

      const userHasRole = user.roles.some(
        (userRole) => userRole.id === role.id,
      );

      return roleHasPermission && userHasRole;
    });
  };

  // (vue.prototype as Vue)
  vue.config.globalProperties.hasFeatureFlag = function (
    feature: string | Feature,
  ): boolean {
    if (!this.store.state.user) {
      return false;
    }

    return hasFeatureFlag(feature, this.store.state.user);
  };
};
