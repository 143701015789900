import AsyncPatientLinkComponent from "@/components/ui/AsyncPatientLink.vue";
import PatientLinkComponent from "@/components/ui/PatientLink.vue";
import FluxBadgeComponent from "@/components/ui/Badge.vue";
import FluxFormComponent from "@/components/ui/Form/Form.vue";
import FluxLongFormDividerComponent from "@/components/ui/Form/LongFormDivider.vue";
import FluxLongFormComponent from "@/components/ui/Form/LongForm.vue";
import FluxLongFormItemComponent from "@/components/ui/Form/LongFormItem.vue";
import FluxShortFormComponent from "@/components/ui/Form/ShortForm.vue";
import FluxShortFormItemComponent from "@/components/ui/Form/ShortFormItem.vue";
import FluxSubmitButton from "@/components/ui/Form/SubmitButton.vue";
import FluxInputComponent from "@/components/ui/Input/Input.vue";
import FluxInputBoxComponent from "@/components/ui/Input/InputBox.vue";
import FluxSelectComponent from "@/components/ui/Input/Select.vue";
import FluxMultiselectComponent from "@/components/ui/Input/Multiselect.vue";
import FluxPhoneNumberComponent from "@/components/ui/Input/PhoneNumber.vue";
import FluxButtonComponent from "@/components/ui/Input/Button.vue";
import FluxButtonGroupComponent from "@/components/ui/Input/ButtonGroup.vue";
import FluxRadioButtonComponent from "@/components/ui/Input/RadioButton.vue";
import FluxRadioButtonGroupComponent from "@/components/ui/Input/RadioButtonGroup.vue";
import FluxRadioButtonSmallComponent from "@/components/ui/Input/RadioButtonSmall.vue";
import FluxCheckboxComponent from "@/components/ui/Input/Checkbox.vue";
import FluxCheckboxLabelComponent from "@/components/ui/Input/CheckboxLabel.vue";
import FluxAlertComponent from "@/components/ui/Alert.vue";
import FluxCalloutComponent from "@/components/ui/Callout.vue";
import FluxCardComponent from "@/components/ui/Card.vue";
import FluxCardButtonHeaderComponent from "@/components/ui/CardButtonHeader.vue";
import FluxModalComponent from "@/components/ui/Modal.vue";
import FluxDropdownComponent from "@/components/ui/Dropdown/Dropdown.vue";
import FluxDropdownItemComponent from "@/components/ui/Dropdown/DropdownItem.vue";
import FluxDropdownButtonComponent from "@/components/ui/Input/DropdownButton.vue";
import FluxTooltipComponent from "@/components/ui/Tooltip.vue";
import FluxTabsComponent from "@/components/ui/Tabs.vue";
import FluxTabComponent from "@/components/ui/Tab.vue";
import FluxSecondaryMenuComponent from "@/components/ui/SecondaryMenu.vue";
import FluxSecondaryMenuItemComponent from "@/components/ui/SecondaryMenuItem.vue";
import FluxTableComponent from "@/components/ui/Table/Table.vue";
import FluxTableColumnComponent from "@/components/ui/Table/TableColumn.vue";
import FluxSwitchComponent from "@/components/ui/Input/Switch.vue";
import FluxTextareaComponent from "@/components/ui/Input/Textarea.vue";
import FluxRichTextareaComponent from "@/components/ui/Input/RichTextarea.vue";
import FluxLabelComponent from "@/components/ui/Label.vue";
import FluxFloatingMenuComponent from "@/components/ui/FloatingMenu.vue";
import FluxFloatingMenuItemComponent from "@/components/ui/FloatingMenuItem.vue";
import FluxAgbCodeInputComponent from "@/components/ui/Input/AgbCodeInput.vue";
import FluxTextDisplayComponent from "@/components/ui/TextDisplay.vue";
import { App } from "vue";
import EditorItemComponent from "@/components/questionnaire/editor/Item.vue";
import EnabledComponent from "@/components/questionnaire/editor/Enabled.vue";

export const FluxUi = {
  install(Vue: App, options: any) {
    Vue.component("patient-link", PatientLinkComponent);
    Vue.component("async-patient-link", AsyncPatientLinkComponent);
    Vue.component("flux-badge", FluxBadgeComponent);
    Vue.component("flux-form", FluxFormComponent);
    Vue.component("flux-long-form-divider", FluxLongFormDividerComponent);
    Vue.component("flux-long-form", FluxLongFormComponent);
    Vue.component("flux-long-form-item", FluxLongFormItemComponent);
    Vue.component("flux-short-form", FluxShortFormComponent);
    Vue.component("flux-short-form-item", FluxShortFormItemComponent);
    Vue.component("flux-submit-button", FluxSubmitButton);
    Vue.component("flux-input", FluxInputComponent);
    Vue.component("flux-input-box", FluxInputBoxComponent);
    Vue.component("flux-select", FluxSelectComponent);
    Vue.component("flux-multiselect", FluxMultiselectComponent);
    Vue.component("flux-phone-number", FluxPhoneNumberComponent);
    Vue.component("flux-button", FluxButtonComponent);
    Vue.component("flux-button-group", FluxButtonGroupComponent);
    Vue.component("flux-radio-button", FluxRadioButtonComponent);
    Vue.component("flux-radio-button-group", FluxRadioButtonGroupComponent);
    Vue.component("flux-radio-button-small", FluxRadioButtonSmallComponent);
    Vue.component("flux-alert", FluxAlertComponent);
    Vue.component("flux-callout", FluxCalloutComponent);
    Vue.component("flux-card", FluxCardComponent);
    Vue.component("flux-card-button-header", FluxCardButtonHeaderComponent);
    Vue.component("flux-modal", FluxModalComponent);
    Vue.component("flux-dropdown", FluxDropdownComponent);
    Vue.component("flux-dropdown-item", FluxDropdownItemComponent);
    Vue.component("flux-dropdown-button", FluxDropdownButtonComponent);
    Vue.component("flux-checkbox", FluxCheckboxComponent);
    Vue.component("flux-checkbox-label", FluxCheckboxLabelComponent);
    Vue.component("flux-tooltip", FluxTooltipComponent);
    Vue.component("flux-tabs", FluxTabsComponent);
    Vue.component("flux-tab", FluxTabComponent);
    Vue.component("flux-secondary-menu", FluxSecondaryMenuComponent);
    Vue.component("flux-secondary-menu-item", FluxSecondaryMenuItemComponent);
    //@ts-ignore Generic typing in component screws things up
    Vue.component("flux-table", FluxTableComponent);
    Vue.component("flux-table-column", FluxTableColumnComponent);
    Vue.component("flux-switch", FluxSwitchComponent);
    Vue.component("flux-textarea", FluxTextareaComponent);
    Vue.component("flux-rich-textarea", FluxRichTextareaComponent);
    Vue.component("flux-label", FluxLabelComponent);
    Vue.component("flux-floating-menu", FluxFloatingMenuComponent);
    Vue.component("flux-floating-menu-item", FluxFloatingMenuItemComponent);
    Vue.component("flux-agb-code-input", FluxAgbCodeInputComponent);
    Vue.component("flux-text-display", FluxTextDisplayComponent);

    // These are needed for the questionnaire editor to work, because otherwise there will be a circular dependency.
    Vue.component("editor-item", EditorItemComponent);
    Vue.component("editor-enabled", EnabledComponent);
  },
};
