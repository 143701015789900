import hotkeyManager from "@/libraries/utils/hotkeyManager";
import { Hotkey } from "@/models/Hotkey";
import { onBeforeUnmount, onMounted, Ref, watch } from "vue";

export function useHotkey(
  hotkey: Ref<Hotkey | undefined>,
  action: () => void,
  options: { useManualRegistering?: boolean } = { useManualRegistering: false },
) {
  const sym = Symbol();

  onMounted(() => {
    if (!options.useManualRegistering) {
      setTimeout(registerIfExists, 1, hotkey.value, sym, action);
    }
  });

  onBeforeUnmount(() => {
    unregisterIfExists(hotkey.value, sym);
  });

  watch(hotkey, (newHotkey, oldHotkey) => {
    unregisterIfExists(oldHotkey, sym);
    registerIfExists(newHotkey, sym, action);
  });

  function register() {
    registerIfExists(hotkey.value, sym, action);
  }

  function unregister() {
    unregisterIfExists(hotkey.value, sym);
  }

  return { register, unregister };
}

function registerIfExists(
  hotkey: Hotkey | undefined,
  sym: Symbol,
  action: () => void,
) {
  if (!hotkey) {
    return;
  }
  hotkeyManager.registerHotkey(hotkey, sym, action);
}

function unregisterIfExists(hotkey: Hotkey | undefined, sym: Symbol) {
  if (!hotkey) {
    return;
  }
  hotkeyManager.unregisterHotkey(hotkey, sym);
}
