<template>
  <flux-form :errors="errors">
    <!-- Start and end date -->
    <flux-short-form>
      <flux-short-form-item
        :label="$t('patient.create.form.step.payer_insurers.start_date')"
        span="col-span-6"
      >
        <flux-input
          v-model:modelValue="modelValue.start_date"
          icon="fal fa-calendar-alt"
          :prop="propRoot + 'start_date'"
          type="date"
          @error="emit('error', $event)"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.payer_insurers.end_date')"
        span="col-span-6"
      >
        <flux-input
          v-model:modelValue="modelValue.end_date"
          icon="fal fa-calendar-alt"
          :prop="propRoot + 'end_date'"
          type="date"
          @error="emit('error', $event)"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.payer_insurers.insurer')"
      >
        <AutocompleteComponent
          v-model:modelValue="searchInput"
          :autocomplete="autocomplete"
          :toLabel="getInsurerString"
          :placeholder="getInsurerString(getInsurerById(modelValue.insurer_id))"
          @add="selectInsurerAndSetSearchString($event)"
          @blur="
            searchInput = getInsurerString(
              getInsurerById(modelValue.insurer_id),
            )
          "
          @focus="searchInput = ''"
        />
      </flux-short-form-item>
      <flux-short-form-item
        prop="insurer_name"
        :label="$t('patient.create.form.step.payer_insurers.insurer_name')"
        span="col-span-6"
      >
        <flux-input
          v-model:modelValue="modelValue.insurer_name"
          :prop="propRoot + 'insurer_name'"
          :disabled="!!modelValue.insurer_id"
          @error="emit('error', $event)"
        />
      </flux-short-form-item>
      <flux-short-form-item
        prop="insurer_number"
        :label="$t('patient.create.form.step.payer_insurers.insurer_number')"
        span="col-span-6"
      >
        <flux-input
          v-model:modelValue="modelValue.insurer_number"
          :prop="propRoot + 'insurer_number'"
          :disabled="!!modelValue.insurer_id"
          @error="emit('error', $event)"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.payer_insurers.number')"
        span="col-span-6"
      >
        <flux-input
          v-model:modelValue="modelValue.number"
          :prop="propRoot + 'number'"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.payer_insurers.type')"
        span="col-span-6"
      >
        <flux-select v-model:modelValue="modelValue.type">
          <option
            v-for="insurance_type in ['B', 'A', 'BZ', 'H', 'T', 'AT', 'OTH']"
            :key="insurance_type"
            :label="$t('labels.insurance_type.' + insurance_type)"
            :value="insurance_type"
          >
            {{ $t("labels.insurance_type." + insurance_type) }}
          </option>
        </flux-select>
      </flux-short-form-item>
      <flux-short-form-item :label="'Pakketnaam'" span="col-span-12">
        <flux-input
          v-model:modelValue="modelValue.package_name"
          :prop="propRoot + 'package_name'"
        />
      </flux-short-form-item>
    </flux-short-form>
  </flux-form>
</template>

<script setup lang="ts">
import { PayerInsurerForm } from "../../models/PayerInsurer";
import { Insurer, insurerIsActive } from "../../models/Insurer";
import { MessageBag } from "@/libraries/utils/MessageBag";
import AutocompleteComponent from "@/components/ui/Input/Autocomplete.vue";
import { parseISO } from "date-fns";
import { pinia } from "@/libraries/store";
import { useInsurerStore } from "@/libraries/store/Insurers";
import { ref, computed, onMounted, watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: PayerInsurerForm;
    formIndex?: number;
    formPropRoot?: string;
    errors?: MessageBag;
  }>(),
  {
    formIndex: 0,
    errors: undefined,
  },
);

const emit = defineEmits<{
  error: [boolean];
  "update:modelValue": [PayerInsurerForm];
}>();

const insurers = ref<Insurer[]>([]);
const loading_insurers = ref(false);

const searchInput = ref("");

const insurerStore = useInsurerStore(pinia);

onMounted(() => {
  fetchInsurers();
});

const propRoot = computed(() => {
  return props.formPropRoot
    ? props.formPropRoot + "." + props.formIndex.toString() + "."
    : "";
});

watch(
  () => props.modelValue?.insurer_id,
  (newId?: number) => {
    if (!newId) {
      return;
    }

    const insurer = insurers.value.find((ins) => ins.id === newId);
    if (insurer) {
      props.modelValue.insurer_name = insurer.name;
      props.modelValue.insurer_number = insurer.number;
    }
  },
);

function filterActive(insurers: Insurer[]) {
  if (!props.modelValue.start_date) {
    return insurers;
  }

  return insurers.filter((insurer) => {
    return insurerIsActive(
      insurer,
      props.modelValue.start_date,
      props.modelValue.end_date,
    );
  });
}

async function fetchInsurers() {
  loading_insurers.value = true;
  insurers.value = await insurerStore.findAll();
  loading_insurers.value = false;
}

function autocomplete() {
  const activeInsurers = filterActive(insurers.value);
  if (searchInput.value === "") {
    return activeInsurers;
  }
  return activeInsurers.filter((insurer) =>
    getInsurerString(insurer)
      .toUpperCase()
      .includes(searchInput.value.toUpperCase()),
  );
}

function selectInsurerAndSetSearchString(insurer: Insurer) {
  props.modelValue.insurer_id = insurer.id;
  searchInput.value = getInsurerString(insurer);
}

function getInsurerById(id: number | null | undefined) {
  return filterActive(insurers.value).find((insurer) => insurer.id === id);
}

function getInsurerString(insurer?: Insurer) {
  if (!insurer) {
    return "";
  }
  return insurer.name + " (" + insurer.number + ")";
}
</script>
