import { MessageBag } from "../utils/MessageBag";
import Manager from "./Manager";

export interface FormInput {
  el: Element;
  prop: string;
  error: boolean;
  errorMessage?: string;
}

export interface FormManagerOptions {
  shouldScrollToError?: boolean;
  submitOnEnter?: boolean;
}

export default class FormManager extends Manager<FormInput> {
  public shouldScrollToError: boolean;
  public submitOnEnter: boolean;
  public formHasError = false;
  private onSubmit: ((event: Event) => void) | undefined = undefined;

  constructor(
    options: FormManagerOptions = {
      shouldScrollToError: true,
      submitOnEnter: false,
    },
  ) {
    super();
    this.shouldScrollToError = options.shouldScrollToError ?? true;
    this.submitOnEnter = options.submitOnEnter ?? true;
  }

  setErrors(msgBag: MessageBag) {
    if (msgBag.isEmpty()) {
      return;
    }
    const keys = msgBag.keys();
    this.items.forEach((item) => {
      item.error = keys.includes(item.prop);
      item.errorMessage = item.error ? msgBag.get(item.prop)[0] : undefined;
    });
    this.setFormHasError();

    if (this.shouldScrollToError) {
      this.scrollErrorIntoView();
    }
  }

  setError(prop: string, error: boolean) {
    let item = this.items.find((item) => item.prop === prop);
    if (!item) {
      return;
    }
    item.error = error;
    this.setFormHasError();
    if (!error) {
      item.errorMessage = undefined;
    }
  }

  hasError(prop: string) {
    return this.items.find((item) => item.prop === prop)?.error ?? false;
  }

  getErrorMessage(prop: string) {
    return this.items.find((item) => item.prop === prop)?.errorMessage;
  }

  setFormHasError() {
    this.formHasError = this.items.some((item) => item.error);
  }

  scrollErrorIntoView() {
    const errorItems = this.items.filter((item) => item.error);
    this.getHighestItem(errorItems)?.el.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  getHighestItem(itemArray?: FormInput[]) {
    if (!itemArray) {
      itemArray = this.items;
    }
    const min = itemArray.reduce(
      (prevMin, { el }) => Math.min(prevMin, el.getBoundingClientRect().y),
      Infinity,
    );
    return itemArray.find((item) => item.el.getBoundingClientRect().y === min);
  }

  setSubmitListener(onSubmit: (event: Event) => void) {
    this.onSubmit = onSubmit;
  }

  submit(event: Event) {
    if (this.onSubmit) {
      this.onSubmit(event);
    }
  }
}
