import moment from "moment";

export const datetimeFilter = (
  datetime: number | string | moment.Moment | Date,
) => {
  if (moment.isMoment(datetime)) {
    return datetime.format("DD-MM-YYYY HH:mm");
  }
  return moment(datetime).format("DD-MM-YYYY HH:mm");
};

export const timeRangeFilter = (
  start: number | string | moment.Moment | Date,
  end: number | string | moment.Moment | Date,
) => {
  return `${timeFilter(start)} - ${timeFilter(end)}`;
};

export const timeFilter = (time: number | string | moment.Moment | Date) => {
  return moment(time).format("HH:mm");
};

export const dateHumanFilter = (
  datetime: number | string | moment.Moment | Date,
) => {
  if (moment(datetime).isSame(moment(), "year")) {
    return moment(datetime).format("ddd D MMM");
  }
  return moment(datetime).format("ddd D MMM YYYY");
};

export const datetimeHumanFilter = (
  datetime: number | string | moment.Moment | Date,
) => {
  if (moment(datetime).isSame(moment(), "year")) {
    return moment(datetime).format("ddd D MMM [om] HH:mm");
  }
  return moment(datetime).format("ddd D MMM YYYY [om] HH:mm");
};

export const compactDatetimeHumanFilter = (
  datetime: number | string | moment.Moment | Date,
) => {
  return moment(datetime).format("dd D MMM [om] HH:mm");
};

export const dateFilter = (date: number | string | Date | moment.Moment) => {
  if (moment.isMoment(date)) {
    return date.format("DD-MM-YYYY");
  }
  return moment(date).format("DD-MM-YYYY");
};

export const dateFilterWithDay = (
  datetime: number | string | moment.Moment | Date,
) => {
  if (moment.isMoment(datetime)) {
    return datetime.format("ddd DD-MM-YYYY");
  }
  return moment(datetime).format("ddd DD-MM-YYYY");
};

export const filesizeFilter = (bytes: number): string => {
  const prefixes = [
    { prefix: "T", size: 1e12 },
    { prefix: "G", size: 1e9 },
    { prefix: "M", size: 1e6 },
    { prefix: "k", size: 1e3 },
  ];

  const prefix = prefixes.find(({ size }) => {
    return size <= bytes;
  });

  if (prefix === undefined) {
    return bytes + " B";
  }

  const n = bytes / prefix.size;
  return n.toPrecision(3) + " " + prefix.prefix + "B";
};

export const money = (cents: number) =>
  "€ " +
  (cents / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+\,)/g, "$1.")
    .replace(",00", ",-");

export const moneyTabular = (cents: number) =>
  "€ " +
  (cents / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
