import { GenerateInvoiceBatchType } from "@/composables/generateInvoiceResult";
import { $t } from "@/libraries/i18n";
import { getUser } from "@/libraries/plugins/getUser";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { Router } from "vue-router";
import { telemetryManager } from "@/libraries/telemetry/Manager";
import { apiClient } from "@/libraries/utils/axios";
import {
  Appointment,
  AppointmentStatus,
  getCompanyParticipants,
  getPatientParticipants,
} from "@/models/Appointment";
import { getEditUrl, Invoice } from "@/models/Invoice";
import { InvoiceEntry } from "@/models/InvoiceEntry";
import { useNotify } from "@/composables/notify";
import { format } from "date-fns";

const { notify } = useNotify();

export type GenerateInvoiceBacthItem = {
  patient_zis_number: number | undefined;
  appointment_id: number | string;
};

export async function generateInvoicesForAppointment(
  appointment: Appointment,
  router: Router,
) {
  const patients = getPatientParticipants(appointment);

  patients.forEach((patient) => {
    generateInvoice(
      router,
      patient.patient_zis_number,
      appointment,
      undefined,
      appointment.referral_id,
    );
  });

  if (patients.length === 0 && getCompanyParticipants(appointment).length > 0) {
    generateInvoice(
      router,
      undefined,
      appointment,
      undefined,
      appointment.referral_id,
    );
  }
}

export async function generateInvoice(
  router: Router,
  patient_zis_number?: number,
  appointment?: { id: number; status: AppointmentStatus },
  medical_update_id?: number,
  referral_id?: number,
): Promise<void> {
  if (!getUser().healthcare_provider.invoice_generation_settings.enabled) {
    return;
  }
  const res = await generateInvoiceRequest(
    patient_zis_number,
    appointment?.id,
    medical_update_id,
    referral_id,
  );
  const invoice = res.invoice;
  if (invoice !== undefined) {
    notify({
      message: $t("finance.invoice.response.created"),
      type: "success",
      action: {
        text: $t("finance.invoice.response.view_generated"),
        callback: () => router.push(getEditUrl(invoice)),
      },
    });
  } else {
    notify({
      message: `${res.message}`,
      type: "error",
    });
  }
}

function shouldGenerateInvoice(appointment: {
  status: AppointmentStatus;
}): boolean {
  if (
    !hasFeatureFlag("invoice-generation") ||
    !getUser().healthcare_provider.invoice_generation_settings.enabled
  ) {
    return false;
  }
  if (appointment.status === "FULFILLED") {
    return true;
  }
  if (
    appointment.status === "NOSHOW" &&
    getUser().healthcare_provider.invoice_generation_settings
      .generate_for_no_shows
  ) {
    return true;
  }
  return false;
}

export async function generateInvoiceRequest(
  patient_zis_number?: number,
  appointment_id?: number,
  medical_update_id?: number,
  referral_id?: number,
): Promise<{
  invoice?: Invoice & { invoice_entries: InvoiceEntry[] };
  message?: string;
}> {
  const { data: response } = await apiClient.post("/invoices/generate", {
    patient_zis_number,
    appointment_id,
    medical_update_id,
    referral_id,
  });

  if (response.invoice) {
    telemetryManager.queueEntry({
      action: "invoice.generated",
      context: {
        status: status,
        invoice_id: response.invoice.id,
      },
    });
  }

  return response;
}

export async function batchGenerateInvoice(
  batch: GenerateInvoiceBacthItem[],
  type: GenerateInvoiceBatchType,
  router: Router,
  date?: Date,
): Promise<void> {
  if (!getUser().healthcare_provider.invoice_generation_settings.enabled) {
    return;
  }
  const res = await batchGenerateInvoiceRequest(batch, type, date);
  const invoice = res.invoice;
  if (invoice !== undefined) {
    notify({
      message: $t("finance.invoice.response.created"),
      type: "success",
      action: {
        text: $t("finance.invoice.response.view_generated"),
        callback: () => router.push(getEditUrl(invoice)),
      },
    });
  }
}

export async function batchGenerateInvoiceRequest(
  batch: GenerateInvoiceBacthItem[],
  type: GenerateInvoiceBatchType,
  date?: Date,
): Promise<{ invoice?: Invoice & { invoice_entries: InvoiceEntry[] } }> {
  const { data: response } = await apiClient.post("/invoices/generate-batch", {
    batch,
    type,
    date: date !== undefined ? format(date, "yyyy-MM-dd") : undefined,
  });
  return response;
}

export async function batchRefreshInvoiceRequest(batch: {
  invoice_entry_ids: number[];
  collection_invoice_id: number;
}): Promise<{ invoice?: Invoice & { invoice_entries: InvoiceEntry[] } }> {
  const { data: response } = await apiClient.post(
    "/invoices/refresh-batch",
    batch,
  );
  return response;
}
